import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { desktop, tabletDown } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksFeatures3,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, Wrapper } from "./styles";

export default function Features3({
  data,
  global,
}: {
  data: PageBlocksFeatures3;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data, "titles")}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      {data.titles?.map((v, i) => (
        <Feature key={i} {...{ v, i }} />
      ))}
    </Wrapper>
  );
}

function Feature({ v, i }: { v?: string | null; i: number }) {
  return (
    <Block key={i}>
      <div
        css={`
          cursor: pointer;
          position: relative;
          ${desktop(css`
            width: 32px;
            height: 32px;
            flex: 0 0 32px;
          `)}

          ${tabletDown(
            css`
              text-align: start;
              width: 28px;
              height: 28px;
              flex: 0 0 28px;
              margin: 1rem 1rem 1rem 0;
            `
          )}
          > img {
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            transform: scale(1);
            object-fit: cover;
            position: relative;
            width: 100%;
            height: 100%;
          }
          &:hover {
            > img {
              transform: scale(1.2);
            }
          }
        `}
      >
        <img src={"/icons/checkmark.svg"} alt="Bulletpoint" />
      </div>
      <div
        css={`
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: start;
          justify-content: start;
          ${desktop(
            css`
              padding: 0;
            `
          )}
          ${tabletDown(
            css`
              padding: 0;
            `
          )}
        `}
      >
        <Text
          t={"caption"}
          td="headline3"
          tm="headline3"
          tt="headline3"
          mt="1rem"
        >
          <Markdown>{v}</Markdown>
        </Text>
      </div>
    </Block>
  );
}
Features3.blockName = "PageBlocksFeatures3";
