import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    $positioning?: PositioningParams | null;
    $global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.$positioning && position(t.$positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.$global?.sidePadding?.desktop || "calc(100% / 12)"};
          justify-content: flex-start;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.$global?.sidePadding?.tablet || "2rem"};
          justify-content: flex-start;
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.$global?.sidePadding?.mobile || "2rem"};
          justify-content: flex-start;
        `
      )}
    box-sizing: border-box;
    flex-flow: row wrap;
    gap: 1rem 3rem;
    overflow: hidden;
    > img,
    div > img {
      width: 100%;
    }
  `,
  Block = styled.div`
    ${desktop(
      css`
        flex: 0 0 calc(25% - 2.5rem);
      `
    )}
    ${tablet(
      css`
        flex: 0 0 calc(50% - 2rem);
      `
    )}
  ${mobile(
      css`
        flex: 0 0 calc(100% - 1rem);
      `
    )}
  flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    overflow: hidden;
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: start;
      justify-content: center;
    }
  `,
  Button = styled.button`
    height: 40px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-1"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    ${(t) => t.theme.font.button}
  `,
  TitleText = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 0;
        flex: 0 1 66%;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0;
        flex: 0 1 calc(100% - 2rem);
      `
    )}
  `,
  BodyText = styled(Text)`
    ${desktop(
      css`
        flex: 0 0 calc(50% - 2rem);
      `
    )}
  `;
