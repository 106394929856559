import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
  tabletUp,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
          gap: 3rem 2rem;
          justify-content: center;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
          gap: 1rem 2rem;
          justify-content: center;
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
          gap: 0 0;
          justify-content: flex-start;
        `
      )}
    box-sizing: border-box;
    ${tabletUp(
      css`
        justify-content: center;
      `
    )}
    flex-flow: row wrap;
  `,
  Block = styled(SafeLink)<{ $imageHeight?: string }>`
    ${desktop(
      css`
        flex: 1 0 calc(25% - 2.3rem);
        max-width: calc(25%);
      `
    )}
    ${tablet(
      css`
        flex: 0 0 calc(50% - 1rem);
      `
    )}
    ${mobile(
      css`
        flex: 0 0 calc(25% - 2.3rem);
      `
    )}
    min-width: 230px;
    padding: 0 0rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;
    > div.image {
      width: 100%;
      position: relative;
      ${(t) =>
        desktop(
          css`
            padding-bottom: ${t.$imageHeight || "100%"};
          `
        )}
      ${(t) =>
        tablet(
          css`
            padding-bottom: ${t.$imageHeight || "204px"};
          `
        )}
      ${(t) =>
        mobile(
          css`
            padding-bottom: ${t.$imageHeight || "100%"};
          `
        )}

      > img {
        transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scale(1);
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        ${desktop(
          css`
            height: 100%;
          `
        )}
        ${tablet(
          css`
            height: 100%;
          `
        )}
      ${mobile(
          css`
            height: 100%;
          `
        )}
      }
    }

    &:hover {
      > div > img {
        transform: scale(1.02);
      }
    }
    > div.content {
      padding: 2rem 0 0 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: start;
      justify-content: start;
    }
  `,
  TextDescription = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 0;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0rem;
      `
    )}
  `;
