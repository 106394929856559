import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const FeatureBlock = styled(motion.div)`
  ${desktop(
    css`
      flex: 0 0 calc(33.3% - 2rem);
    `
  )}
  ${tablet(
    css`
      flex: 0 0 calc(100% - 2rem);
      margin-left: auto;
    `
  )}
    ${mobile(
    css`
      flex: 0 0 calc(100% - 2rem);
      margin-left: auto;
    `
  )}
    min-width: 230px;
  padding: 0 0rem;
  flex-direction: column;
  display: flex;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 0;
`;

export const HorizontalArrow = styled(motion.div)`
  //width: 100%;
  height: 1px;
  border-top: 2px dotted grey;
  position: relative;
  bottom: 6rem;
`;
export const VerticalArrow = styled(motion.div)`
  position: absolute;
  border-left: 2px dotted grey;
`;
export const WrapperInfo = styled.div`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;

    ${desktop(
      css`
        padding: 0 calc(100% / 12) 0 calc(100% / 12);
        justify-content: flex-start;
      `
    )}
    ${tabletDown(
      css`
        padding: 0 2rem;
        justify-content: flex-start;
      `
    )}
  box-sizing: border-box;
    flex-flow: row wrap;
    gap: 1rem 3rem;
    overflow: hidden;
    > img {
      width: 100%;
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: scale(1);
      cursor: pointer;
      :hover {
        transform: scale(1.01);
      }
    }
  `,
  TitleText = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 0rem;
        flex: 0 1 66%;
      `
    )}
    ${tablet(
      css`
        margin-bottom: 0rem;
        flex: 0 1 calc(100% - 2rem);
      `
    )}
    ${mobile(
      css`
        margin-bottom: 0rem;
        flex: 1 1 auto;
      `
    )}
  `,
  BodyText = styled(Text)`
    ${desktop(
      css`
        flex: 0 0 calc(50% - 2rem);
      `
    )}
  `,
  Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
          gap: 3rem 3rem;
          justify-content: flex-start;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"};
          gap: 1rem 2rem;
          justify-content: flex-start;
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"};
          gap: 0 0;
          justify-content: center;
        `
      )}
    box-sizing: border-box;
    flex-flow: row wrap;
  `,
  Block = styled.div`
    ${desktop(
      css`
        flex: 0 0 calc(33.3% - 2rem);
      `
    )}
    ${tablet(
      css`
        flex: 0 0 calc(100% - 2rem);
        margin-left: auto;
      `
    )}
    ${mobile(
      css`
        flex: 0 0 calc(100% - 2rem);
        margin-left: auto;
      `
    )}
    min-width: 230px;
    padding: 0 0rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;
  `,
  ImageContainer = styled.div`
    width: 100%;
    position: relative;
    > img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,
  TextTitle = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 0rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0.5rem;
      `
    )}
  `,
  TextDescription = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 2rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0rem;
      `
    )}
  `;
