import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  MobileOnly,
  TabletDown,
  TabletOnly,
  mobile,
  sizes,
  tablet,
} from "@/lib";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksGallery2,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { TopWrapper, Wrapper } from "./styles";

export default function Gallery2({
  data,
  global,
}: {
  data: PageBlocksGallery2;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState(0);

  return (
    <TopWrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
    >
      <DesktopOnly>
        <Wrapper>
          <div
            css={`
              display: flex;
              padding: 0 ${global?.sidePadding?.desktop || "calc(100vw / 12)"}
                2rem ${global?.sidePadding?.desktop || "calc(100vw / 12)"};
              justify-content: center;
              align-items: center;
            `}
          >
            <Text
              data-tina-field={tinaField(data, "titleText")}
              t="headline1"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="space-between"
              mt="0"
            >
              <Markdown>{data?.titleText}</Markdown>
            </Text>
          </div>
          <SwipeableViews
            index={index}
            onChangeIndex={setIndex}
            style={{
              padding: "0 2rem 0 0",
              width: "560px",
              overflow: "visible",
              // marginRight: "560px",
              marginLeft: global?.sidePadding?.desktop || "calc(100vw / 12)",
              height: "320px",
            }}
            slideStyle={{
              width: "560px",
              marginRight: "40px",
            }}
            enableMouseEvents
          >
            {data.features?.map((v, i) => (
              <SafeLink
                key={i}
                href={v?.imageLink}
                css={`
                  position: relative;
                `}
              >
                <ImageOrVideoSet
                  width="560"
                  height="320"
                  src={v?.image || "/dummy.png"}
                  alt={v?.imageAlt || v?.title || "Image description"}
                  data-tina-field={v && tinaField(v, "image")}
                  css={`
                    width: 560px !important;
                    min-height: 320px;
                    height: 30vh !important;
                    cursor: pointer;
                    position: relative !important;
                    object-fit: cover;
                  `}
                  imageSet={v?.imageSet}
                />
              </SafeLink>
            ))}
          </SwipeableViews>
          <div
            css={`
              display: flex;
              padding: 2rem
                ${global?.sidePadding?.desktop || "calc(100vw / 12)"} 0
                ${global?.sidePadding?.desktop || "calc(100vw / 12)"};
              justify-content: center;
              align-items: center;
            `}
          >
            {data.features?.map((v, i) => (
              <div
                key={i}
                onClick={() => setIndex(i)}
                style={{
                  width: index === i ? "11px" : "10px",
                  height: index === i ? "11px" : "10px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  margin: "0.5rem",
                  backgroundColor: index === i ? "white" : "#bbb",
                }}
              ></div>
            ))}
          </div>
        </Wrapper>
      </DesktopOnly>
      <TabletDown>
        <Wrapper>
          <div
            css={`
              display: flex;
              ${tablet(css`
                padding: 0 ${global?.sidePadding?.tablet || "2rem"} 2rem
                  ${global?.sidePadding?.tablet || "2rem"};
              `)}
              ${mobile(css`
                padding: 0 ${global?.sidePadding?.mobile || "2rem"} 2rem
                  ${global?.sidePadding?.mobile || "2rem"};
              `)}
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text
              data-tina-field={tinaField(data, "titleText")}
              t={"headline1"}
              tt="headline1"
              tm="headline2"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="space-between"
              mt="0"
              mb="0"
            >
              <Markdown>{data?.titleText}</Markdown>
            </Text>
          </div>
          <TabletOnly>
            <SwipeableViews
              index={index}
              onChangeIndex={setIndex}
              style={{
                padding: `0 ${global?.sidePadding?.tablet || "2rem"}`,
                width: "245px",
                overflow: "visible",
                marginRight: "400px",
                marginLeft: "0rem",
              }}
              slideStyle={{
                width: "245px",
                marginRight: "2rem",
              }}
              enableMouseEvents
            >
              {data.features?.map((v, i) => (
                <SafeLink
                  key={i}
                  href={v?.imageLink}
                  css={`
                    position: relative;
                    padding-bottom: 138%;
                  `}
                >
                  <ImageOrVideoSet
                    width="245"
                    height="338"
                    src={v?.image || "/dummy.png"}
                    alt={v?.imageAlt || v?.title || "Image description"}
                    data-tina-field={v && tinaField(v, "image")}
                    css={`
                      width: 245px;
                      cursor: pointer;
                      object-fit: cover;
                    `}
                    imageSet={v?.imageSet}
                  />
                </SafeLink>
              ))}
            </SwipeableViews>
          </TabletOnly>
          <MobileOnly>
            <SwipeableViews
              index={index}
              onChangeIndex={setIndex}
              style={{
                padding: `0 ${global?.sidePadding?.mobile || "2rem"}`,
                width: "90%",
                overflow: "visible",
                marginRight: "400px",
                marginLeft: "0rem",
              }}
              slideStyle={{
                width: "90%",
                marginRight: global?.sidePadding?.mobile || "2rem",
              }}
              enableMouseEvents
            >
              {data.features?.map((v, i) => (
                <SafeLink
                  key={i}
                  href={v?.imageLink}
                  css={`
                    position: relative;
                    padding-bottom: 138%;
                  `}
                >
                  <ImageOrVideoSet
                    fill
                    sizes={sizes("100vw", "100vw", "100vw")}
                    src={v?.image || "/dummy.png"}
                    alt={v?.imageAlt || v?.title || "Image description"}
                    data-tina-field={v && tinaField(v, "image")}
                    css={`
                      width: 100%;
                      cursor: pointer;
                      object-fit: cover;
                    `}
                    imageSet={v?.imageSet}
                  />
                </SafeLink>
              ))}
            </SwipeableViews>
          </MobileOnly>
          <div
            css={`
              display: flex;
              margin-top: 1rem;
              ${tablet(css`
                padding: 0 ${global?.sidePadding?.tablet || "2rem"} 2rem
                  ${global?.sidePadding?.tablet || "2rem"};
              `)}
              ${mobile(css`
                padding: 0 ${global?.sidePadding?.mobile || "2rem"} 2rem
                  ${global?.sidePadding?.mobile || "2rem"};
              `)}
              justify-content: center;
              align-items: center;
            `}
          >
            {data.features?.map((v, i) => (
              <div
                key={i}
                onClick={() => setIndex(i)}
                style={{
                  width: index === i ? "11px" : "10px",
                  height: index === i ? "11px" : "10px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  margin: "0.5rem",
                  backgroundColor: index === i ? "white" : "#bbb",
                }}
              ></div>
            ))}
          </div>
        </Wrapper>
      </TabletDown>
    </TopWrapper>
  );
}
Gallery2.blockName = "PageBlocksGallery2";
