import { getArrowString } from "@/components/Layout/Header/Header1";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import {
  GlobalPartsFragment,
  PageBlocksGallery3,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Button } from "../../Hero/styles";
import { Content, Heading, TopWrapper, Wrapper } from "./styles";

const StackGridComponent = dynamic(import("./grid"), { ssr: false });

export default function Gallery3({
  data,
  global,
}: {
  data: PageBlocksGallery3;
  global?: GlobalPartsFragment;
}) {
  const [category, setCategory] = useState(0);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <TopWrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
    >
      <Wrapper global={global}>
        <Heading style={{ justifyContent: data.verticalAlign || "start" }}>
          <Text
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
            mt="0"
            data-tina-field={tinaField(data, "titleText")}
          >
            <Markdown>{data.titleText}</Markdown>
          </Text>
          {data?.imageCategories?.map((v, i) => (
            <Text
              t="body"
              mt="0.5rem"
              css={`
                transition: font-weight 0.5s cubic-bezier();
                cursor: pointer;
                :hover {
                  font-weight: 550 !important;
                  text-decoration: underline;
                }
              `}
              key={i}
              data-tina-field={v && tinaField(v, "title")}
              onClick={() => setCategory(i)}
              style={{ fontWeight: category === i ? 550 : 500 }}
            >
              {v?.title}
            </Text>
          ))}
          {data?.buttonText && (
            <SafeLink href={data?.buttonLink}>
              <Button style={{ width: "fit-content" }}>
                {getArrowString(data?.buttonText)}
              </Button>
            </SafeLink>
          )}
        </Heading>
        <Content>
          <div
            data-tina-field={tinaField(data, "imageCategories")}
            style={{ width: "100%" }}
          >
            {mounted && data?.imageCategories?.[category]?.images?.length && (
              <StackGridComponent
                data={data?.imageCategories?.[category]?.images || []}
              />
            )}
          </div>
        </Content>
      </Wrapper>
    </TopWrapper>
  );
}
Gallery3.blockName = "PageBlocksGallery3";
