import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import { desktop, tabletDown } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksResources1,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import Arrow from "../../../../public/icons/arrow-down.svg";
import { Button } from "../../Hero/styles";
import { Wrapper } from "../styles";
export default function Resources1({
  data,
  global,
}: {
  data: PageBlocksResources1;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <div
        css={`
          position: relative;
          display: flex;
          flex-wrap: wrap;
          flex: 1 1 auto;
        `}
      >
        <Text
          t="headline2"
          td="headline2"
          tm="headline3"
          tt="headline3"
          c="text/primary"
          mt="0"
          css={`
            position: absolute;
            display: flex;
            top: 0;
            left: 0;
            ${tabletDown(
              css`
                position: relative;
              `
            )}
          `}
        >
          {data.title}
        </Text>
        {data.features?.map((v, i) => (
          <div
            key={i}
            css={`
              height: 124px;
              ${desktop(
                css`
                  height: 164px;
                  padding-left: 50%;
                `
              )}
              :not(:nth-child(2)) {
                margin-top: 2rem;
              }
              flex: 1 1 100%;
              border-bottom: 1px solid grey;
              flex-direction: column;
            `}
          >
            <Text
              t="headline4"
              c="text/primary"
              mt="0"
              css={`
                font-weight: 400 !important;
                ${desktop(
                  css`
                    margin: 0.2rem 0 2.2rem 0;
                  `
                )}
              `}
            >
              {v?.title}
            </Text>
            <SafeLink href={v?.buttonLink || v?.buttonFile}>
              <Button
                css={`
                  min-width: 50%;
                  width: fit-content;
                  ${tabletDown(
                    css`
                      width: 100%;
                    `
                  )}
                `}
              >
                {v?.buttonText}
                <Arrow
                  css={`
                    width: 0.8rem;
                    margin-left: 1rem;
                  `}
                />
              </Button>
            </SafeLink>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
Resources1.blockName = "PageBlocksResources1";
