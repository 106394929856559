import { sizes } from "@/lib";
import Image from "next/image";
import {
  GlobalPartsFragment,
  Maybe,
  PageBlocksPartners2,
  PageBlocksPartners2Partners,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, Grid, ImageContainer, TitleText, Wrapper } from "./styles";

export default function Partners2({
  data,
  global,
}: {
  data: PageBlocksPartners2;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <TitleText
        t={"headline1"}
        td="headline1"
        tt="headline2"
        tm="headline2"
        flex="0 1 100%"
        mt="0"
        data-tina-field={tinaField(data, "title")}
      >
        {data.title}
      </TitleText>
      <Grid>
        {data.partners?.map((v, i) => (
          <Partner key={i} {...{ v, i, data }} />
        ))}
      </Grid>
    </Wrapper>
  );
}

function Partner({
  v,
  i,
  data,
}: {
  v: Maybe<PageBlocksPartners2Partners>;
  i: number;
  data: PageBlocksPartners2;
}) {
  return (
    <Block
      href={v?.link}
      key={i}
      data-tina-field={v && tinaField(v)}
      data={data}
    >
      <ImageContainer data-tina-field={v && tinaField(v, "logo")}>
        <Image
          fill
          sizes={sizes("100vw", "33vw", "20vw")}
          alt={v?.title || ""}
          src={v?.logo || "/icons/mobile.svg"}
          css={`
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            transform: scale(1);
            object-fit: contain;
            position: absolute;
            left: ${data.layout?.offset_x || "30"}% !important;
            right: ${data.layout?.offset_x || "30"}% !important;
            top: ${data.layout?.offset_y || "10"}% !important;
            bottom: ${data.layout?.offset_y || "10"}% !important;
            width: calc(
              100% - ${data.layout?.offset_x ? data.layout.offset_x * 2 : "60"}%
            ) !important;
            height: calc(
              100% - ${data.layout?.offset_y ? data.layout.offset_y * 2 : "20"}%
            ) !important;
          `}
        />
      </ImageContainer>
    </Block>
  );
}
Partners2.blockName = "PageBlocksPartners2";
