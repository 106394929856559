import { position, PositioningParams } from "@/lib";
import styled, { css } from "styled-components";

export const Wrapper = styled.div<{
    $position?: "fixed" | "relative" | string;
    height?: number;
    filter?: string;
    shadowDisabled?: boolean;
    positioning?: PositioningParams | null;
  }>`
    width: 100vw;
    height: ${(t) => t.height || 100}vh;
    position: relative;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    overflow: hidden;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      !t.shadowDisabled &&
      css`
        box-shadow: inset 0px 20px 50px rgba(0, 0, 0, 0.2);
      `}
    >div.image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
      z-index: -1;
      > img {
        width: 100%;
        height: 100%;
        max-width: 100vw;
        object-fit: cover;
        position: ${(t) => t.$position || "fixed"};
        ${(t) =>
          css`
            ${t.filter &&
            css`
              filter: ${t.filter};
            `}
          `}
        ${(t) =>
          t.$position === "fixed" &&
          css`
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            /* transform: translate(-50%, -50%); */
          `}
      z-index: -1;
      }
    }
  `,
  TextContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  `;
