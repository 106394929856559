import { desktop, mobile, tablet } from "@/lib";
import { PageData } from "@/pages";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

export const BlocksWrapper = styled.div<{
    global?: PageData["data"]["global"];
  }>`
    display: flex;
    position: relative;
    flex-direction: column;
    ${(t) =>
      css`
        ${desktop(
          t?.global?.verticalGap
            ? css`
                gap: ${t?.global?.verticalGap?.desktop} 0;
              `
            : css`
                gap: 0px 0px;
              `
        )}
        ${tablet(
          t?.global?.verticalGap
            ? css`
                gap: ${t?.global?.verticalGap?.tablet} 0;
              `
            : css`
                gap: 0px 0px;
              `
        )}
      ${mobile(
          t?.global?.verticalGap
            ? css`
                gap: ${t?.global?.verticalGap?.mobile} 5rem;
              `
            : css`
                gap: 0px 0px;
              `
        )}
      `}
  `,
  Content = styled.div`
    background-color: ${(t) => t.theme.colors["background/bg-2"]};
    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(t) => t.theme.font.display3}
    color: rgba(255,255,255,0.3);
  `,
  BlockDummy = styled.div.attrs<{ children?: ReactNode }>((props: any) => ({
    children: <Content>{props?.children || "Content"}</Content>,
  }))`
    width: 100%;
    height: 75vh;
    min-height: calc(100vw / 6 + 100px);
    background-color: ${(t) => t.theme.colors["background/bg-1"]};
    padding: calc(100vw / 12);
    position: relative;
  `;
