import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { MobileOnly, TabletUp, sizes, toString } from "@/lib";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  GlobalPartsFragment,
  Maybe,
  PageBlocksFeatures5,
  PageBlocksFeatures5Features,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, TextDescription, Wrapper } from "./styles";
export default function Features5({
  data,
  global,
}: {
  data: PageBlocksFeatures5;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState(0);

  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <Text
        t={"headline1"}
        td="headline1"
        tt="headline2"
        tm="headline2"
        flex="0 1 100%"
        data-tina-field={tinaField(data, "title")}
        mt="0"
      >
        {data.title}
      </Text>
      <TabletUp>
        {data.features?.map((v, i) => (
          <Feature key={i} {...{ v, i, data }} />
        ))}
      </TabletUp>
      <MobileOnly>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          style={{
            padding: "0 0",
            width: "calc(226px + 2rem)",
            overflow: "visible",
            marginRight: "0rem",
            marginLeft: "0rem",
          }}
          slideStyle={{
            width: "calc(226px + 2rem)",
            marginRight: "2rem",
          }}
          enableMouseEvents
        >
          {data.features?.map((v, i) => (
            <Feature key={i} {...{ v, i, data }} />
          ))}
        </SwipeableViews>
        <Scroller {...{ data, index, setIndex }} />
      </MobileOnly>
    </Wrapper>
  );
}

function Scroller({
  data,
  index,
  setIndex,
}: {
  data: PageBlocksFeatures5;
  index: number;
  setIndex: (v: number) => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        padding: "0rem",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data.features?.map((v, i) => (
        <div
          key={i}
          onClick={() => setIndex(i)}
          style={{
            width: index === i ? "11px" : "10px",
            height: index === i ? "11px" : "10px",
            borderRadius: "50%",
            cursor: "pointer",
            margin: "0 0.5rem",
            backgroundColor: index === i ? "white" : "#bbb",
          }}
        ></div>
      ))}
    </div>
  );
}

function Feature({
  v,
  i,
  data,
}: {
  v: Maybe<PageBlocksFeatures5Features>;
  i: number;
  data: PageBlocksFeatures5;
}) {
  return (
    <Block
      key={i}
      href={v?.imageLink || ""}
      $imageHeight={data?.imageHeight || ""}
      data-tina-field={v && tinaField(v)}
    >
      <div className="image" data-tina-field={v && tinaField(v, "image")}>
        <ImageOrVideoSet
          fill
          src={v?.image || "/dummy.png"}
          sizes={sizes("100vw", "50vw", "25vw")}
          alt={v?.imageAlt || toString(v?.description)}
          imageSet={v?.imageSet}
        />
      </div>
      <div className="content">
        <Text
          t={"headline3"}
          td="headline3"
          tt="headline4"
          tm="headline4"
          mb={"1rem"}
          data-tina-field={v && tinaField(v, "title")}
        >
          {v?.title || ""}
        </Text>
        <TextDescription
          t={"caption"}
          td="caption"
          tt="button"
          tm="button"
          mb="0"
          data-tina-field={v && tinaField(v, "description")}
        >
          <Markdown>{v?.description}</Markdown>
        </TextDescription>
      </div>
    </Block>
  );
}
Features5.blockName = "PageBlocksFeatures5";
