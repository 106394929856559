import type { PageQuery } from "tina/__generated__/types";
import * as Animation from "./Animation";
import * as Blog from "./Blog";
import * as CallToAction from "./CallToAction";
import * as Cards from "./Cards";
import * as Faq from "./FAQ";
import * as Features from "./Features";
import * as Forms from "./Forms";
import * as Gallery from "./Gallery";
import * as Hero from "./Hero";
import * as Image from "./Image";
import * as Informational from "./Informational";
import * as Numbers from "./Numbers";
import * as Partners from "./Partners";
import * as Resources from "./Resources";
import * as Video from "./Video";

import { PageData } from "@/pages";
import { getArticles } from "./Blog/Blog1";
import DividerBlock from "./Divider";
import { BlocksWrapper } from "./styles";

const templates = [
  ...Object.values(Animation),
  ...Object.values(Blog),
  ...Object.values(CallToAction),
  ...Object.values(Cards),
  ...Object.values(Faq),
  ...Object.values(Features),
  ...Object.values(Gallery),
  ...Object.values(Hero),
  ...Object.values(Informational),
  ...Object.values(Video),
  ...Object.values(Numbers),
  ...Object.values(Image),
  ...Object.values(Partners),
  ...Object.values(Resources),
  ...Object.values(Forms),
  DividerBlock,
];

export const Blocks = (props: {
  blocks?: PageQuery["page"]["blocks"];
  global?: PageData["data"]["global"];
  articles?: Awaited<ReturnType<typeof getArticles>> | undefined;
}) => {
  return (
    <BlocksWrapper global={props.global}>
      {(props.blocks || []).map(function (block, i) {
        const Component = templates.find(
          (v) => v.blockName === block?.__typename
        );

        if (block?.__typename && Component) {
          return (
            <Component
              data={block as any}
              key={i + block.__typename}
              global={props?.global}
              articles={props?.articles}
            />
          );
        } else {
          return null;
        }
      })}
      <div className="spacing"></div>
    </BlocksWrapper>
  );
};
