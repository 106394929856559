import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import {
  GlobalPartsFragment,
  PageBlocksInformational16,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, TitleText, Wrapper } from "./styles";

export default function Informational16({
  data,
  global,
}: {
  data: PageBlocksInformational16;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      {data?.title && (
        <>
          <TitleText
            data-tina-field={tinaField(data, "title")}
            mt={0}
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
          >
            <Markdown>{data?.title}</Markdown>
          </TitleText>
        </>
      )}

      {data.features?.map((v, i) => (
        <Block key={i} data-tina-field={v && tinaField(v)}>
          <Text
            t="display3"
            css={`
              text-align: start;
              font-size: 48px;
              margin-bottom: 0;
              margin-bottom: 0;
            `}
            data-tina-field={v && tinaField(v, "header")}
          >
            {v?.header || ""}
          </Text>
          <div
            css={`
              height: 10px !important;
              margin-bottom: 0;
              margin-top: 0;
              overflow: visible;
              position: relative;
              width: calc(100% - 2px);
            `}
          >
            <div
              className="divider"
              css={`
                height: 1px;
                transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                position: absolute;
                background-color: grey;
                width: 100%;
              `}
            />

            <svg
              width="3.5px"
              height="6px"
              fill="none"
              css={`
                transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                position: absolute;
                right: 0px;
              `}
            >
              <path
                stroke="grey"
                strokeWidth="1"
                strokeLinejoin="miter"
                d="M0 0L3 3L0 6"
              />
            </svg>
          </div>
          <div>
            <Text
              t="headline3"
              as="h2"
              css={`
                height: 1rem !important;
                margin-bottom: 0.5rem;
              `}
              data-tina-field={v && tinaField(v, "title")}
            >
              {v?.title || ""}
            </Text>
            <Text
              t="caption"
              mt="2rem"
              mb="auto"
              data-tina-field={v && tinaField(v, "description")}
            >
              <Markdown>{v?.description || ""}</Markdown>
            </Text>
          </div>
        </Block>
      ))}
    </Wrapper>
  );
}
Informational16.blockName = "PageBlocksInformational16";
