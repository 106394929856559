import Markdown from "@/components/shared/Markdown";
import { sizes } from "@/lib";
import { getFilters } from "@/lib/filters";
import { default as NextImage } from "next/image";
import { PageBlocksImage } from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { TextContainer, Wrapper } from "./styles";

export default function Image({ data }: { data: PageBlocksImage }) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      shadowDisabled={data.shadowDisabled || undefined}
      filter={data.filters ? getFilters(data.filters) : undefined}
      height={data.height || undefined}
      $position={data.position || undefined}
      positioning={data.positioning}
    >
      <div className="image" data-tina-field={tinaField(data, "image")}>
        <NextImage
          fill
          sizes={sizes("100vw", "100vw", "100vw")}
          alt={data.text?.data || data.text || ""}
          src={data.image ? encodeURI(data.image) : "/dummy.png"}
          css={`
            position: relative !important;
            object-position: ${data.horizontalAlign || "center"}
              ${data.verticalAlign || "center"};
          `}
        />
      </div>
      {data.text && (
        <TextContainer data-tina-field={tinaField(data, "text")}>
          <Markdown>{data.text}</Markdown>
        </TextContainer>
      )}
    </Wrapper>
  );
}
Image.blockName = "PageBlocksImage";
