import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { DesktopOnly, MobileOnly, TabletOnly } from "@/lib";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  GlobalPartsFragment,
  Maybe,
  PageBlocksFeatures1,
  PageBlocksFeatures1Features,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, TitleText, Wrapper } from "./styles";

export default function Features1({
  data,
  global,
}: {
  data: PageBlocksFeatures1;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState(0);

  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <TitleText
        t={"headline1"}
        td="headline1"
        tt="headline2"
        tm="headline2"
        flex="0 1 100%"
        data-tina-field={tinaField(data, "title")}
        mt="0"
      >
        {data.title}
      </TitleText>

      <DesktopOnly>
        {data.features?.map((v, i) => (
          <Feature key={i} {...{ v, i }} />
        ))}
      </DesktopOnly>
      <TabletOnly>
        <div
          css={`
            width: 100%;
          `}
        >
          <SwipeableViews
            index={index}
            onChangeIndex={setIndex}
            style={{
              padding: "0 0",
              width: "242px",
              overflow: "visible",
              marginRight: "0rem",
              marginLeft: "0rem",
            }}
            slideStyle={{
              width: "242px",
              marginRight: "2rem",
            }}
            enableMouseEvents
          >
            {data.features?.map((v, i) => (
              <Feature key={i} {...{ v, i }} />
            ))}
          </SwipeableViews>
          <Scroller {...{ data, index, setIndex }} />
        </div>
      </TabletOnly>
      <MobileOnly>
        <div
          css={`
            width: 100%;
          `}
        >
          <SwipeableViews
            index={index}
            onChangeIndex={setIndex}
            style={{
              padding: "0 0",
              width: "calc(226px + 2rem)",
              overflow: "visible",
              marginRight: "0rem",
              marginLeft: "0rem",
            }}
            slideStyle={{
              width: "calc(226px + 2rem)",
              marginRight: "2rem",
            }}
            enableMouseEvents
          >
            {data.features?.map((v, i) => (
              <Feature key={i} {...{ v, i }} />
            ))}
          </SwipeableViews>
          <Scroller {...{ data, index, setIndex }} />
        </div>
      </MobileOnly>
    </Wrapper>
  );
}

function Scroller({
  data,
  index,
  setIndex,
}: {
  data: PageBlocksFeatures1;
  index: number;
  setIndex: (v: number) => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        padding: "0rem",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data.features?.map((v, i) => (
        <div
          key={i}
          onClick={() => setIndex(i)}
          style={{
            width: index === i ? "11px" : "10px",
            height: index === i ? "11px" : "10px",
            borderRadius: "50%",
            cursor: "pointer",
            margin: "0 0.5rem",
            backgroundColor: index === i ? "white" : "#bbb",
          }}
        ></div>
      ))}
    </div>
  );
}

function Feature({
  v,
  i,
}: {
  v: Maybe<PageBlocksFeatures1Features>;
  i: number;
}) {
  return (
    <Block
      href={v?.imageLink || ""}
      key={i}
      data-tina-field={v && tinaField(v)}
    >
      <div
        className="image"
        data-tina-field={v && tinaField(v, "image")}
        style={{ padding: v?.imagePadding || undefined }}
      >
        <img
          css={`
            object-fit: contain !important;
          `}
          alt={v?.title || ""}
          src={v?.image || "/icons/mobile.svg"}
        />
      </div>
      <div className="content">
        <Text
          t={"headline3"}
          td="headline3"
          tt="headline4"
          tm="headline4"
          data-tina-field={v && tinaField(v, "title")}
        >
          {v?.title || ""}
        </Text>
        <Text
          t={"caption"}
          td="caption"
          tm="button"
          tt="button"
          data-tina-field={v && tinaField(v, "description")}
          mb="0"
        >
          <Markdown>{v?.description}</Markdown>
        </Text>
      </div>
    </Block>
  );
}
Features1.blockName = "PageBlocksFeatures1";
