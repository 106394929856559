import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    gap: 1rem 3rem;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    overflow: hidden;
    grid: 2rem 2rem;
    a {
      cursor: pointer;
    }
  `,
  Block = styled.div`
    flex: 1 1 100%;
    min-width: 230px;
    flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    img {
      margin-left: 1rem;
    }
  `,
  InputBlock = styled.div`
    display: flex;
    flex-direction: row;
  `,
  Button = styled.button`
    height: 40px;
    padding: 0 2rem;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${desktop(
      css`
        margin-top: 3rem;
      `
    )}
    ${tabletDown(
      css`
        margin-top: 1rem;
      `
    )}
  cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-1"]};
    :hover {
      border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    ${(t) => t.theme.font.button}
  `,
  Input = styled.input`
    height: 40px;
    flex: 1 1 auto;
    padding: 0 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    color: ${(t) => t.theme.colors["text/primary"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-1"]};
    }
    :active {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    :focus {
      outline: none;
    }
    ${(t) => t.theme.font.button}
  `;
