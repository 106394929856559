import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { DesktopOnly, TabletDown, sizes, tabletDown } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksInformational4,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import {
  Block,
  NumberBlock,
  Numbers,
  TopWrapper,
  ValueText,
  Wrapper,
} from "./styles";
export default function Informational4({
  data,
  global,
}: {
  data: PageBlocksInformational4;
  global?: GlobalPartsFragment;
}) {
  return (
    <TopWrapper
      positioning={data.positioning}
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
    >
      <TabletDown>
        <Block>
          <ImageOrVideoSet
            fill
            sizes={sizes("100vw", "100vw", "100vw")}
            alt={data.imageAlt || data.title || ""}
            imageSet={data.imageSet}
            src={data.image || "/dummy.png"}
            data-tina-field={tinaField(data, "image")}
            css={`
              position: relative !important;
              margin-bottom: 2rem !important;
              margin-left: 0 !important;
            `}
          />
        </Block>
      </TabletDown>
      <Wrapper global={global}>
        <Block
          style={{
            paddingRight: "calc(100vw / 12)",
            justifyContent: data.verticalAlign || "start",
          }}
        >
          <Text
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
            data-tina-field={tinaField(data, "title")}
            mt={0}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
          <Text
            t="body"
            c="text/primary"
            mb={"2rem"}
            css={`
              ${tabletDown(
                css`
                  margin-bottom: 0;
                `
              )}
            `}
            data-tina-field={tinaField(data, "text")}
          >
            <Markdown>{data?.text}</Markdown>
          </Text>
          {data.numbers && data.numbers.length !== 0 && (
            <Numbers>
              {data.numbers.map((v, i) => (
                <NumberBlock key={i} data-tina-field={v && tinaField(v)}>
                  <ValueText
                    t={"headline1"}
                    td="display3"
                    tt="headline1"
                    tm="headline1"
                    mt={0}
                    mb="1rem"
                    data-tina-field={v && tinaField(v, "value")}
                  >
                    {v?.value}
                  </ValueText>

                  <Text
                    t="caption"
                    data-tina-field={v && tinaField(v, "description")}
                  >
                    <Markdown>{v?.description}</Markdown>
                  </Text>
                </NumberBlock>
              ))}
            </Numbers>
          )}
        </Block>
        <DesktopOnly>
          <Block style={{ flex: "0 0 50%" }}>
            <ImageOrVideoSet
              fill
              sizes={sizes("50vw", "50vw", "50vw")}
              alt={data.imageAlt || data.title || ""}
              imageSet={data.imageSet}
              src={data.image || "/dummy.png"}
              data-tina-field={tinaField(data, "image")}
              css={`
                position: relative !important;
              `}
            />
          </Block>
        </DesktopOnly>
      </Wrapper>
    </TopWrapper>
  );
}
Informational4.blockName = "PageBlocksInformational4";
