import { getArrowString } from "@/components/Layout/Header/Header1";
import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import { DesktopOnly, TabletDown, sizes } from "@/lib";
import { Fragment } from "react";
import {
  GlobalPartsFragment,
  PageBlocksInformational14,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Button } from "../../Hero/styles";
import { Block, SafeLinkStatementBlock, TopWrapper, Wrapper } from "./styles";
export default function Informational14({
  data,
  global,
}: {
  data: PageBlocksInformational14;
  global?: GlobalPartsFragment;
}) {
  return (
    <TopWrapper
      positioning={data.positioning}
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
    >
      <Wrapper global={global}>
        {data.changeSides && (
          <DesktopOnly>
            <Block style={{ flex: "1 1 100%", paddingRight: "0" }}>
              <ImageOrVideoSet
                fill
                sizes={sizes("50vw", "50vw", "50vw")}
                alt={data.imageAlt || data.title || ""}
                css={`
                  object-fit: contain;
                  position: relative !important;
                `}
                src={data.image || "/dummy.png"}
                data-tina-field={tinaField(data, "image")}
                imageSet={data.imageSet}
              />
            </Block>
          </DesktopOnly>
        )}
        <Block
          changeSides={!!data.changeSides}
          style={{
            justifyContent: data.verticalAlign || "start",
            marginTop: data.marginTop || undefined,
          }}
        >
          <div>
            <Text
              t={"headline1"}
              td="headline1"
              tt="headline2"
              tm="headline2"
              data-tina-field={tinaField(data, "title")}
              mt={0}
            >
              <Markdown>{data?.title}</Markdown>
            </Text>
            <Text
              t="body"
              c="text/primary"
              mb={"2rem"}
              data-tina-field={tinaField(data, "text")}
            >
              <Markdown>{data?.text}</Markdown>
              {data.buttonText && (
                <SafeLink href={data?.buttonLink}>
                  <Button
                    css={`
                      width: fit-content;
                      > svg {
                        fill: white;
                      }
                    `}
                  >
                    {getArrowString(data.buttonText)}
                  </Button>
                </SafeLink>
              )}
            </Text>
            {data?.statements?.length ? (
              <Divider c="text/primary" mb="2rem" />
            ) : (
              <></>
            )}
            {data.statements?.map((v, i) => (
              <Fragment key={i}>
                <SafeLinkStatementBlock key={i}>
                  <img
                    src="/icons/check.svg"
                    alt="Bulletpoint"
                    width="18"
                    height="14"
                  />
                  <Text t="body" m="0">
                    {v}
                  </Text>
                </SafeLinkStatementBlock>
              </Fragment>
            ))}
          </div>
        </Block>
        {!data.changeSides && (
          <DesktopOnly>
            <Block style={{ flex: "1 1 100%", paddingRight: 0 }}>
              <ImageOrVideoSet
                fill
                sizes={sizes("50vw", "50vw", "50vw")}
                alt={data.imageAlt || data.title || ""}
                css={`
                  object-fit: contain;
                  position: relative !important;
                `}
                src={data.image || "/dummy.png"}
                imageSet={data.imageSet}
                data-tina-field={tinaField(data, "image")}
              />
            </Block>
          </DesktopOnly>
        )}
      </Wrapper>
      <TabletDown>
        <Block>
          <ImageOrVideoSet
            fill
            sizes={sizes("100vw", "100vw", "100vw")}
            alt={data.imageAlt || data.title || ""}
            css={`
              object-fit: contain;
              position: relative !important;
              margin-top: 1rem;

              width: calc(100vw);
            `}
            src={data.image || "/dummy.png"}
            data-tina-field={tinaField(data, "image")}
            imageSet={data.imageSet}
          />
        </Block>
      </TabletDown>
    </TopWrapper>
  );
}
Informational14.blockName = "PageBlocksInformational14";
