import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
  tabletUp,
} from "@/lib";
import styled, { css, keyframes } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"} 0
            ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
          gap: 1rem 3rem;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    overflow: hidden;
    grid: 2rem 2rem;
    > div {
      flex: 1 1 100%;
    }
    a {
      cursor: pointer;
    }
  `,
  Block = styled.div`
    /* flex: 0 0 50%; */
    min-width: 230px;
    ${desktop(
      css`
        padding-right: 0rem;
      `
    )}
    ${tablet(
      css`
        padding: 0rem;
      `
    )}
    ${mobile(
      css`
        padding: 0;
      `
    )}
    flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    > div.image {
      width: 100%;
      height: fit-content;
      padding-bottom: 100%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  `,
  Numbers = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: 2rem;
    flex-flow: row wrap;
    position: relative;
    justify-content: center;
    gap: 2rem 2rem;
  `,
  NumberBlock = styled.div`
    ${tabletUp(
      css`
        flex: 0 0 calc(50% - 1rem);
      `
    )}
    ${mobile(
      css`
        flex: 0 0 calc(100% - 1rem);
      `
    )}
flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    > div.image {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      img {
        margin-left: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  `,
  ValueText = styled(Text)`
    ${tabletDown(
      css`
        margin-top: 0;
      `
    )}
  `,
  LinkBlock = styled.div`
    padding: 1.5rem 0 1.3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span,
    img {
      pointer-events: none;
      user-select: none;
      transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    }
    :hover {
      text-decoration: none;
    }
  `,
  TopWrapper = styled.div<{ positioning?: PositioningParams | null }>`
    ${(t) => t.positioning && position(t.positioning)}
  `;

//animations Venndiagram
const topCircleAnim = keyframes`
  0%, 100%{
    transform: translateY(0) scale(100%);
  } 45%, 55%{
    transform: translateY(40%)  scale(70%);
  }
`;
const leftCircleAnim = keyframes`
  0%, 100%{
    transform: translate(0,0) scale(100%);
  } 42%, 55%{
    transform: translate(33%, -19%) scale(70%);
  }
`;
const rightCircleAnim = keyframes`
  0%, 100%{
    transform: translate(0,0) scale(100%);
  } 42%, 55%{
    transform: translate(-33%, -19%)  scale(70%);
  }
`;
const textAnim = keyframes`
  0%, 100%{
    opacity: 1;
  } 42%, 65%{
    opacity: 0;
  }
`;

const Circle = styled.div`
  background-color: #1c1c1c;
  mix-blend-mode: screen;
  position: absolute;
  border-radius: 50%;
  width: 60%;
  height: 60%;
  opacity: 100%;
`;

export const TopCircle = styled(Circle)`
  left: 20%;
  animation: ${topCircleAnim} 8s ease-in-out infinite alternate;
`;

export const LeftCircle = styled(Circle)`
  left: 0;
  top: 35.5%;
  animation: ${leftCircleAnim} 8s ease-in-out infinite alternate;
`;
export const RightCircle = styled(Circle)`
  right: 0;
  top: 35.5%;
  animation: ${rightCircleAnim} 8s ease-in-out infinite alternate;
`;
export const TextCircle = styled(Text)`
  font-size: 1.4rem;
  position: absolute;
  word-spacing: 100vw;
  white-space: normal;
  animation: ${textAnim} 8s ease-in-out infinite alternate;
  ${mobile(css`
    font-size: 1.1rem;
  `)};
`;
export const LogoVenn = styled.img`
  height: 10%;
  width: 10%;
  top: 48.5%;
  left: 45%;
  position: absolute;
  mix-blend-mode: screen;
`;
