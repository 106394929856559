import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksPartners2,
} from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    ${(t) => t.positioning && position(t.positioning)}
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
  box-sizing: border-box;
    justify-content: start;
    flex-flow: row wrap;
    gap: 3rem 3rem;
  `,
  Block = styled(SafeLink)<{ data?: PageBlocksPartners2 }>`
    ${(v) => css`
      flex: 0 0
        ${v.data?.layout?.columnsDesktop
          ? 100 / v.data?.layout?.columnsDesktop
          : "25"}%;
      ${tablet(
        css`
          flex: 0 0
            ${v.data?.layout?.columnsTablet
              ? 100 / v.data?.layout?.columnsTablet
              : "33"}%;
        `
      )}
      ${mobile(
        css`
          flex: 0 0
            ${v.data?.layout?.columnsMobile
              ? 100 / v.data?.layout?.columnsMobile
              : "50"}%;
        `
      )}
      background-color: ${v.data?.backgroundColor || "rgba(255, 255, 255, 0)"};
    `}
    padding: 0 0rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;

    > div.content {
      ${desktop(
        css`
          padding: 2rem 0;
        `
      )}
      ${tabletDown(
        css`
          padding: 0rem 0;
        `
      )}
    height: 100%;
      display: flex;
      flex-direction: column;
      text-align: start;
      justify-content: start;
    }
  `,
  ImageContainer = styled.div`
    cursor: pointer;
    position: relative;
    width: 100%;
    flex: 0 0 60px;
    padding: 0.5rem;
    padding-bottom: 40%;
    outline: ${(t) => t.theme.colors["text/secondary"]} solid 1px;
    outline-offset: -0.5px;
    overflow: hidden;
    &:hover {
      > img {
        transform: scale(1.05);
      }
    }
  `,
  TitleText = styled(Text)`
    ${tabletDown(
      css`
        margin-bottom: 0;
      `
    )}
  `,
  Grid = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1px;
  `;
