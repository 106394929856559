import Markdown from "@/components/shared/Markdown";
import { useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  SvgBackground,
  SvgSlide,
  SvgWrapper,
  TitleText,
  Wrapper,
} from "./styles";

import {
  GlobalPartsFragment,
  PageBlocksAnimation1,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";

export default function AnimatedSvg({
  data,
  global,
}: {
  data: PageBlocksAnimation1;
  global?: GlobalPartsFragment;
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1 });
    }
  }, [controls, inView]);

  return (
    <Wrapper
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
      data-tina-field={data}
    >
      {data?.title && (
        <>
          <TitleText
            data-tina-field={tinaField(data, "title")}
            mt={0}
            t={"headline1"}
            td="headline1"
            tt="body"
            tm="body"
            mb="0"
          >
            <Markdown>{data?.title}</Markdown>
          </TitleText>
        </>
      )}

      <SvgWrapper>
        <SvgBackground
          src={"/svg/technologystack_backg3.jpg"}
          alt="Technology Stack - How do we Operate"
          width={"100%"}
          height={"100%"}
        />
        {Array.from(Array(8).keys()).map((d: any, i: number) => (
          <SvgSlide
            ref={ref}
            animate={controls}
            initial={{ opacity: 0 }}
            transition={{
              delay: (i + 1) * (data?.fduration || 0.55),
              duration: (data?.fduration || 0.55) * 1.1,
            }}
            src={"/svg/0" + (i + 1) + ".svg"}
            alt={"Technology Stack - slide " + (i + 1)}
            key={"child" + i}
          />
        ))}
      </SvgWrapper>
    </Wrapper>
  );
}

AnimatedSvg.blockName = "PageBlocksAnimation1";
