import Markdown from "@/components/shared/Markdown";
import { Divider, Text } from "@/components/shared/Text";
import { MobileOnly, TabletUp, desktop, mobile, tablet, useSize } from "@/lib";
import { Dispatch, SetStateAction, useState } from "react";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  Maybe,
  PageBlocksFaq4,
  PageBlocksFaq4Questions,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import {
  Answer,
  ContainerBlock,
  Question,
  TitleBlock,
  TitleText,
  TopWrapper,
  Wrapper,
} from "./styles";

export function Faq4({
  data,
  global,
}: {
  data: PageBlocksFaq4;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState<number | null>(null);
  return (
    <TopWrapper
      data-tina-field={data}
      id={data.id || undefined}
      positioning={data.positioning}
    >
      {data?.title && data?.titleWidth && (
        <TabletUp>
          <Wrapper global={global}>
            <Text
              css={`
                width: ${data?.titleWidth || "50%"};
              `}
              t={"headline1"}
              td="headline1"
              tt="headline2"
              tm="headline2"
              data-tina-field={tinaField(data, "title")}
              mt={0}
            >
              <Markdown>{data?.title}</Markdown>
            </Text>
          </Wrapper>
        </TabletUp>
      )}

      <Wrapper
        css={`
          padding-top: ${data?.titleWidth ? 0 : "inherit"} !important;
        `}
        global={global}
      >
        <TabletUp>
          <TitleBlock>
            {!data?.titleWidth && (
              <Text
                mt={0}
                t={"headline1"}
                td="headline1"
                tt="headline2"
                tm="headline2"
                mb="2rem"
                data-tina-field={tinaField(data, "title")}
                css={`
                  ${desktop(
                    css`
                      padding-right: calc(100vw / 12);
                    `
                  )}
                  ${tablet(
                    css`
                      padding-right: 2rem;
                    `
                  )}
                  ${mobile(
                    css`
                      padding-right: 0;
                    `
                  )}
                `}
              >
                <Markdown>{data?.title}</Markdown>
              </Text>
            )}
          </TitleBlock>
        </TabletUp>

        <ContainerBlock bodyWidth={data?.bodyWidth || ""}>
          <MobileOnly>
            <TitleText
              mt={0}
              t={"headline2"}
              mb="2rem"
              data-tina-field={tinaField(data, "title")}
            >
              <Markdown>{data?.title}</Markdown>
            </TitleText>
          </MobileOnly>
          <Divider c="elements/gray-1" mt="0" mb="0" />
          {data.questions?.map((v, i) => (
            <QuestionBox {...{ v, i, index, setIndex }} key={i} />
          ))}
        </ContainerBlock>
      </Wrapper>
    </TopWrapper>
  );
}
Faq4.blockName = "PageBlocksFaq4";

export function QuestionBox({
  v,
  i,
  index,
  setIndex,
}: {
  v: Maybe<PageBlocksFaq4Questions>;
  i: number;
  index?: number | null;
  setIndex: Dispatch<SetStateAction<number | null>>;
}) {
  const { ref, height } = useSize({
    noObserver: false,
  });
  return (
    <div
      data-tina-field={v && tinaField(v)}
      key={i}
      onClick={() => (index === i ? setIndex(null) : setIndex(i))}
    >
      <Question $open={i === index} $grey={index !== i && index !== null}>
        <Text
          t="headline3"
          data-tina-field={v && tinaField(v, "title")}
          m="0"
          css={`
            transition: color 1s cubic-bezier(0.19, 1, 0.22, 1);
          `}
        >
          {v?.title}
        </Text>
        <img
          src="/icons/chevron-down.svg"
          style={{ transform: i === index ? "scaleY(-1)" : "scaleY(1)" }}
          alt="Open answer"
          width="24"
          height="24"
        />
      </Question>
      <Answer open={i === index} maxHeight={height + 30}>
        <Text
          ref={ref}
          t="body"
          data-tina-field={v && tinaField(v, "text")}
          css={`
            position: relative;
            display: block;
          `}
        >
          <Markdown>{v?.text}</Markdown>
        </Text>
        <br />
        <br />
      </Answer>
      <Divider c="elements/gray-1" mt="0" mb="0" />
    </div>
  );
}
