import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  TabletDown,
  desktop,
  mobile,
  sizes,
  tablet,
  toString,
} from "@/lib";
import { Fragment, useState } from "react";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksInformational3,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { QuestionBox } from "../../FAQ/FAQ4";
import { Block, LinkBlock, TopWrapper, Wrapper } from "./styles";
export default function Informational3({
  data,
  global,
}: {
  data: PageBlocksInformational3;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState<number | null>(null);

  return (
    <TopWrapper
      positioning={data.positioning}
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
    >
      <TabletDown>
        <Block
          css={`
            ${tablet(
              css`
                padding: 0 ${global?.sidePadding?.tablet || "2rem"};
              `
            )}
            ${mobile(
              css`
                padding: 0 ${global?.sidePadding?.mobile || "2rem"};
              `
            )}
          `}
        >
          <ImageOrVideoSet
            sizes={sizes("100vw", "100vw", "100vw")}
            fill
            alt={data.imageAlt || data.title || ""}
            css={`
              object-fit: contain;
              position: relative !important;
              margin-bottom: 2rem;
            `}
            src={data.image || "/dummy.png"}
            data-tina-field={tinaField(data, "image")}
            imageSet={data.imageSet}
          />
        </Block>
      </TabletDown>
      <Wrapper global={global}>
        {data.flipSides && (
          <DesktopOnly>
            <ImageBlock {...{ index, data }} />
          </DesktopOnly>
        )}
        <Block>
          <div
            css={`
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              ${desktop(css`
                padding-right: ${!data.flipSides ? "calc(100vw / 12)" : "0"};
                padding-left: ${data.flipSides ? "calc(100vw / 12)" : "0"};
              `)}

              justify-content: ${data.verticalAlign || "start"};
            `}
          >
            <Text
              t={"headline1"}
              td="headline1"
              tt="headline2"
              tm="headline2"
              data-tina-field={tinaField(data, "title")}
              mt={0}
            >
              <Markdown>{data?.title}</Markdown>
            </Text>

            {data.links?.map((v, i) =>
              toString(v?.text?.children)?.length ? (
                <Fragment key={i}>
                  <QuestionBox
                    {...{ i, index, setIndex }}
                    v={{ ...v, __typename: "PageBlocksFaq4Questions" }}
                    key={i}
                  />
                </Fragment>
              ) : (
                <Fragment key={i}>
                  <SafeLink href={v?.link}>
                    <LinkBlock
                      data-tina-field={v && tinaField(v, "link")}
                      key={i}
                    >
                      <Text
                        t="headline3"
                        as="h2"
                        data-tina-field={v && tinaField(v, "title")}
                        m="0"
                      >
                        {v?.title}
                      </Text>
                      <img
                        alt="Follow the link"
                        src="/icons/arrow-right-large.svg"
                        width="13"
                        height="14"
                      />
                    </LinkBlock>
                  </SafeLink>
                  <Divider c="elements/gray-1" mt="0" mb="0" />
                </Fragment>
              )
            )}
          </div>
        </Block>
        {!data.flipSides && (
          <DesktopOnly>
            <ImageBlock {...{ index, data }} />
          </DesktopOnly>
        )}
      </Wrapper>
    </TopWrapper>
  );
}

function ImageBlock({
  data,
  index,
}: {
  data: PageBlocksInformational3;
  index: number | null;
}) {
  return (
    <Block style={{ flex: "1 1 100%" }}>
      <div
        css={`
          position: relative;
          display: block;

          width: 100%;
        `}
      >
        <ImageOrVideoSet
          fill
          sizes={sizes("50vw", "50vw", "50vw")}
          alt={data.imageAlt || data.title || ""}
          css={`
            object-fit: contain;
            position: relative !important;
            transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
            opacity: ${index === null || index === undefined ? "1" : "0"};
          `}
          imageSet={data.imageSet}
          src={data.image || "/dummy.png"}
          data-tina-field={tinaField(data, "image")}
        />

        {data?.links?.map((image, i) => (
          <ImageOrVideoSet
            key={i}
            fill
            sizes={sizes("50vw", "50vw", "50vw")}
            alt={(index !== null && data?.links?.[index]?.imageAlt) || ""}
            css={`
              transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
              opacity: ${index === i ? "1" : "0"};
              object-fit: cover;
              position: absolute !important;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            `}
            imageSet={image?.imageSet}
            src={image?.image}
          />
        ))}
      </div>
    </Block>
  );
}
Informational3.blockName = "PageBlocksInformational3";
