import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { mobile, sizes } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksInformational15,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { TitleText, Wrapper } from "./styles";
export default function Informational15({
  data,
  global,
}: {
  data: PageBlocksInformational15;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      $disableHoverZoom={data.disableHoverZoom || undefined}
      $positioning={data.positioning}
      $global={global}
    >
      {data?.title && (
        <>
          <TitleText
            data-tina-field={tinaField(data, "title")}
            mt={0}
            t={"headline1"}
            td="headline1"
            tt="headline3"
            tm="headline3"
            mb="0"
          >
            <Markdown>{data?.title}</Markdown>
          </TitleText>
        </>
      )}

      <ImageOrVideoSet
        fill
        sizes={sizes("100vw", "100vw", "100vw")}
        alt={data.imageAlt || data.title || ""}
        data-tina-field={tinaField(data, "image")}
        src={data.image}
        css={`
          position: relative !important;
          ${mobile(
            css`
              width: 100vw !important;
              margin-left: -2rem;
              margin-top: 1rem;
            `
          )}
        `}
        imageSet={data.imageSet}
      />
    </Wrapper>
  );
}
Informational15.blockName = "PageBlocksInformational15";
