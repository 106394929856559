import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import { MobileOnly, TabletUp } from "@/lib";
import {
  GlobalPartsFragment,
  PageBlocksCallToAction5,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, Button, InputBlock, Wrapper } from "./styles";

export default function CallToAction5({
  data,
  global,
}: {
  data: PageBlocksCallToAction5;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={data}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <TabletUp>
        <Block>
          <Text
            mt="0"
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
            mb="2rem"
            data-tina-field={tinaField(data, "title")}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
        </Block>
      </TabletUp>
      <Block>
        <MobileOnly>
          <Text
            t="headline2"
            mt="0"
            mb="2rem"
            data-tina-field={tinaField(data, "title")}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
        </MobileOnly>
        <Text
          t="body"
          c="text/primary"
          data-tina-field={tinaField(data, "text")}
        >
          <Markdown>{data?.text}</Markdown>
        </Text>
        <InputBlock>
          <SafeLink href={data.buttonLink || ""}>
            <Button data-tina-field={tinaField(data, "buttonText")}>
              {data.buttonText}
            </Button>
          </SafeLink>
        </InputBlock>
      </Block>
    </Wrapper>
  );
}
CallToAction5.blockName = "PageBlocksCallToAction5";
