import { position, PositioningParams } from "@/lib";
import styled from "styled-components";

export const TopWrapper = styled.div<{
    positioning?: PositioningParams | null;
  }>`
    ${(t) => t.positioning && position(t.positioning)}
  `,
  Wrapper = styled.div`
    width: 100vw;
    position: relative;
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
  `;
