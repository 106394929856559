import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { MobileOnly, TabletUp } from "@/lib";
import {
  GlobalPartsFragment,
  PageBlocksFeatures7,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, Wrapper } from "./styles";

export default function Features7({
  data,
  global,
}: {
  data: PageBlocksFeatures7;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <TabletUp>
        <Block>
          <Text
            mt={0}
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
            mb="2rem"
            data-tina-field={tinaField(data, "title")}
            css={`
              padding-right: calc(100vw / 12);
            `}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
        </Block>
      </TabletUp>
      <Block>
        <MobileOnly>
          <Text
            t="headline2"
            mb="2rem"
            mt="0"
            data-tina-field={tinaField(data, "title")}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
        </MobileOnly>
        {data.features?.map((f, i) => (
          <div
            key={i}
            css={`
              display: flex;
              > span > p {
                margin-bottom: 0.5rem !important;
              }
            `}
          >
            <img
              src="/icons/check.svg"
              alt="Bulletpoint"
              width="18"
              height="18"
              css={`
                margin-right: 1.3rem;
                margin-bottom: 0.5rem;
              `}
            />
            <Text
              t="body"
              c="text/primary"
              key={i}
              data-tina-field={f && tinaField(f, "title")}
            >
              <Markdown>{f?.title}</Markdown>
            </Text>
          </div>
        ))}
      </Block>
    </Wrapper>
  );
}
Features7.blockName = "PageBlocksFeatures7";
