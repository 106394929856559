import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
  tabletUp,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
          gap: 1rem 3rem;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    overflow: hidden;
    grid: 2rem 2rem;
    > div {
      flex: 1 1 100%;
    }
    a {
      cursor: pointer;
    }
  `,
  Block = styled.div<{ changeSides?: boolean }>`
    /* flex: 0 0 50%; */
    min-width: 230px;
    ${(t) =>
      desktop(
        css`
          ${t.changeSides
            ? css`
                > div {
                  margin-left: calc(100vw / 12);
                }
              `
            : css`
                > div {
                  margin-right: calc(100vw / 12);
                }
              `}
        `
      )}
    ${tablet(
      css`
        padding: 0rem;
      `
    )}
  ${mobile(
      css`
        padding: 0;
      `
    )}
  flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    > div.image {
      width: 100%;
      height: fit-content;
      padding-bottom: 100%;
      position: relative;
    }
  `,
  Numbers = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: 2rem;
    flex-flow: row wrap;
    position: relative;
    justify-content: center;
    gap: 2rem 2rem;
  `,
  NumberBlock = styled.div`
    ${tabletUp(
      css`
        flex: 0 0 calc(50% - 1rem);
      `
    )}
    ${mobile(
      css`
        flex: 0 0 calc(100% - 1rem);
      `
    )}
  flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    > div.image {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      img {
        margin-left: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  `,
  ValueText = styled(Text)`
    ${tabletDown(
      css`
        margin-top: 0;
      `
    )}
  `,
  SafeLinkStatementBlock = styled(SafeLink)`
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-bottom: 1rem;
    :hover {
      text-decoration: none;
    }
    img {
      margin-top: 0.4rem;
      margin-right: 1rem;
    }
  `,
  TopWrapper = styled.div<{ positioning?: PositioningParams | null }>`
    ${(t) => t.positioning && position(t.positioning)}
  `;
