import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { DesktopOnly, TabletDown, mobile, sizes, tablet } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksInformational5,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import {
  Block,
  ImageContainer,
  TextBody,
  TitleBlock,
  TopWrapper,
  Wrapper,
} from "./styles";
export default function Informational5({
  data,
  global,
}: {
  data: PageBlocksInformational5;
  global?: GlobalPartsFragment;
}) {
  return (
    <TopWrapper
      positioning={data.positioning}
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
    >
      <TabletDown>
        <Block
          css={`
            padding-right: 0 !important;
          `}
        >
          <ImageContainer
            data-tina-field={tinaField(data, "image")}
            style={{ objectFit: "cover" }}
          >
            <ImageOrVideoSet
              fill
              sizes={sizes("100vw", "100vw", "100vw")}
              alt={data.imageAlt || data.title || ""}
              src={data.image || "/dummy.png"}
              data-tina-field={tinaField(data, "image")}
              css={`
                position: relative !important;
                margin-bottom: 2rem;
              `}
              imageSet={data.imageSet}
            />
          </ImageContainer>
        </Block>
      </TabletDown>
      <Wrapper global={global}>
        <DesktopOnly>
          <Block style={{ flex: "1 1 auto" }}>
            {!data.hideImage && (
              <ImageContainer data-tina-field={tinaField(data, "image")}>
                <ImageOrVideoSet
                  fill
                  sizes={sizes("50vw", "50vw", "50vw")}
                  alt={data.imageAlt || data.title || ""}
                  src={data.image || "/dummy.png"}
                  css={`
                    position: relative !important;
                    position: absolute;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    ${tablet(
                      css`
                        margin: 0 ${global?.sidePadding?.tablet || "2rem"};
                        width: calc(100% - 2rem);
                      `
                    )}
                    ${mobile(
                      css`
                        margin: 0 0rem 0 0rem;
                        width: calc(100% + 2rem);
                      `
                    )}
                  `}
                  imageSet={data.imageSet}
                />
              </ImageContainer>
            )}
          </Block>
        </DesktopOnly>
        <TitleBlock
          style={{
            justifyContent: data.verticalAlign || "start",
            alignItems: "start",
            marginTop: data.marginTop || undefined,
          }}
        >
          <Text
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
            mt={0}
            data-tina-field={tinaField(data, "title")}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
          <TextBody
            t="body"
            c="text/primary"
            data-tina-field={tinaField(data, "text")}
          >
            <Markdown>{data?.text}</Markdown>
          </TextBody>
        </TitleBlock>
      </Wrapper>
    </TopWrapper>
  );
}
Informational5.blockName = "PageBlocksInformational5";
