import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import { DesktopOnly, TabletDown, desktop, sizes, tabletDown } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksInformational10,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, BodyText, Button, TitleText, Wrapper } from "./styles";

export default function Informational10({
  data,
  global,
}: {
  data: PageBlocksInformational10;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      {data?.image && (
        <ImageOrVideoSet
          fill
          sizes={sizes("100vw", "100vw", "100vw")}
          data-tina-field={tinaField(data, "image")}
          src={data.image}
          alt={data.title || ""}
          css={`
            position: relative !important;
            width: 100%;
            flex: 1 1 auto;
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            transform: scale(1);
            cursor: pointer;
            :hover {
              transform: scale(1.01);
            }
            ${desktop(
              css`
                margin-bottom: 4rem;
              `
            )}
            ${tabletDown(
              css`
                margin-bottom: 1rem;
              `
            )}
          `}
          imageSet={data.imageSet}
        />
      )}

      <div
        css={`
          display: flex;
          ${tabletDown(
            css`
              flex-direction: column;
            `
          )}
        `}
      >
        <TitleText
          data-tina-field={tinaField(data, "title")}
          mt={0}
          t={"headline1"}
          td="headline1"
          tt="headline2"
          tm="headline2"
          css={tabletDown(
            css`
              margin-bottom: 1rem;
            `
          )}
        >
          <Markdown>{data?.title}</Markdown>
          <DesktopOnly>
            {data.buttonText && (
              <SafeLink
                href={data.buttonLink}
                data-tina-field={tinaField(data, "buttonLink")}
              >
                <Button data-tina-field={tinaField(data, "buttonText")}>
                  {data.buttonText}
                </Button>
              </SafeLink>
            )}
          </DesktopOnly>
        </TitleText>

        {data?.text && (
          <>
            <BodyText
              data-tina-field={tinaField(data, "text")}
              t="body"
              mt="0"
              mb="0"
            >
              <Markdown>{data?.text}</Markdown>
            </BodyText>
          </>
        )}
      </div>
      <TabletDown>
        {data.buttonText && (
          <SafeLink
            href={data.buttonLink}
            data-tina-field={tinaField(data, "buttonLink")}
          >
            <Button
              data-tina-field={tinaField(data, "buttonText")}
              style={{ marginRight: "auto", marginTop: "-2rem" }}
            >
              {data.buttonText}
            </Button>
          </SafeLink>
        )}
      </TabletDown>

      {data?.features && data?.features.length > 0 && (
        <Divider c="text/primary" />
      )}
      {data.features?.map((v, i) => (
        <Block key={i} data-tina-field={v && tinaField(v)}>
          <div>
            <Text
              t="headline3"
              mb="1rem"
              mt="0"
              data-tina-field={v && tinaField(v, "title")}
            >
              {v?.title || ""}
            </Text>
            <Text
              t="caption"
              mt="auto"
              mb="0"
              data-tina-field={v && tinaField(v, "description")}
            >
              <Markdown>{v?.description || ""}</Markdown>
            </Text>
          </div>
        </Block>
      ))}
    </Wrapper>
  );
}
Informational10.blockName = "PageBlocksInformational10";
