import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  desktop,
  mobile,
  sizes,
  tabletDown,
  toString,
} from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksInformational12,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import {
  Block,
  BodyText,
  Button,
  LineBreak,
  TitleText,
  Wrapper,
} from "./styles";

export default function Informational12({
  data,
  global,
}: {
  data: PageBlocksInformational12;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      $disableHoverZoom={data.disableHoverZoom || undefined}
      $positioning={data.positioning}
      $global={global}
    >
      {data?.title && (
        <>
          <TitleText
            css={`
              ${!data?.text &&
              css`
                padding-right: unset !important;
                margin-right: auto;
              `}
            `}
            customWidth={data?.titleWidth || ""}
            data-tina-field={tinaField(data, "title")}
            mt="0"
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
            mb="0"
          >
            <Markdown>{data?.title}</Markdown>
          </TitleText>
        </>
      )}

      {toString(data.text) && (
        <BodyText
          data-tina-field={tinaField(data, "text")}
          t="body"
          mt="0"
          css={`
            ${desktop(
              css`
                margin-bottom: 2rem;
              `
            )}
            ${tabletDown(
              css`
                margin-bottom: -1rem;
              `
            )}
          `}
        >
          <Markdown>{data?.text || " "}</Markdown>
        </BodyText>
      )}
      {data?.buttonText && (
        <>
          <DesktopOnly>
            <LineBreak />
          </DesktopOnly>
          <SafeLink href={data?.buttonLink}>
            <Button>{data?.buttonText}</Button>
          </SafeLink>
        </>
      )}
      {data?.image && (
        <div
          css={`
            position: relative;
            display: block;
            width: 100%;
            ${desktop(
              css`
                &:not(:first-child) {
                  margin-top: 2rem;
                }
              `
            )}
          `}
        >
          <ImageOrVideoSet
            fill
            sizes={sizes("100vw", "100vw", "100vw")}
            data-tina-field={tinaField(data, "image")}
            src={data.image}
            alt={data.imageAlt || data.title || ""}
            css={`
              position: relative !important;
              object-fit: contain;
              ${mobile(
                css`
                  width: 100vw !important;
                  margin-left: -2rem;
                `
              )}
            `}
            imageSet={data.imageSet}
          />
          {data.hoverImage?.image && (
            <ImageOrVideoSet
              fill
              sizes={sizes("100vw", "100vw", "100vw")}
              data-tina-field={tinaField(data, "hoverImage")}
              src={data.hoverImage?.image}
              alt={data.hoverImage?.imageAlt || ""}
              css={`
                position: absolute !important;
                left: 0;
                top: 0;
                transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                opacity: 0;
                cursor: pointer;
                &:hover {
                  opacity: 1;
                }
                object-fit: cover;
                ${mobile(
                  css`
                    width: 100vw;
                    margin-left: -2rem;
                  `
                )}
              `}
              imageSet={data.hoverImage?.imageSet}
            />
          )}
        </div>
      )}

      {data.features?.map((v, i) => (
        <Block key={i} data-tina-field={v && tinaField(v)}>
          <Divider
            c="text/primary"
            mb="2rem"
            mt="2rem"
            css={`
              height: 0 !important;
            `}
          />
          <div>
            <Text
              t="headline3"
              mb="1rem"
              mt="0"
              data-tina-field={v && tinaField(v, "title")}
            >
              {v?.title || ""}
            </Text>
            <Text
              t="caption"
              mt="0"
              mb="auto"
              data-tina-field={v && tinaField(v, "description")}
            >
              <Markdown>{v?.description || ""}</Markdown>
            </Text>
          </div>
        </Block>
      ))}
    </Wrapper>
  );
}
Informational12.blockName = "PageBlocksInformational12";
