import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import {
  GlobalPartsFragment,
  PageBlocksNumbers2,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, Wrapper } from "./styles";

export default function Numbers2({
  data,
  global,
}: {
  data: PageBlocksNumbers2;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      {data.numbers?.map((v, i) => (
        <Block key={i} data-tina-field={v && tinaField(v)}>
          <Text
            t="display2"
            mb="1rem"
            mt="0"
            data-tina-field={v && tinaField(v, "value")}
          >
            {v?.value === "∞" ? (
              <img src="/icons/noun-infinity.png" alt="Infinity" />
            ) : (
              v?.value
            )}
          </Text>
          <Text t="caption" data-tina-field={v && tinaField(v, "description")}>
            <Markdown>{v?.description || ""}</Markdown>
          </Text>
        </Block>
      ))}
    </Wrapper>
  );
}

Numbers2.blockName = "PageBlocksNumbers2";
