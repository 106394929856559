import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Transition } from "@/components/shared/Transition";
import {
  DesktopOnly,
  MobileOnly,
  TabletOnly,
  desktop,
  mobile,
  tablet,
  tabletDown,
} from "@/lib";
import { getFilters } from "@/lib/filters";
import { useRef } from "react";
import { css, useTheme } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksHero15,
  PageBlocksHero8,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Text } from "../../../shared/Text";
import { Row, Wrapper } from "../styles";
import { DividerContainer } from "./styles";

export default function Hero8({
  data,
  global,
}: {
  data: PageBlocksHero8;
  global?: GlobalPartsFragment;
}) {
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement>();

  // https://player.vimeo.com/video/778573063?h=48708f4660
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      color={
        data.backgroundColor
          ? theme.colors[data.backgroundColor as keyof typeof theme.colors]
          : undefined
      }
      positioning={data.positioning}
      filter={data.filters ? getFilters(data.filters) : undefined}
      onClick={() => videoRef?.current?.play?.()}
      global={global}
    >
      {data.backgroundImage && (
        <div
          css={`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
          `}
        >
          <div
            css={`
              position: relative;
              display: block;
              width: 100vw;
              height: 100vh;
            `}
          >
            <ImageOrVideoSet
              fill
              sizes="100vw"
              priority
              forwardRef={videoRef}
              quality={100}
              alt={data.imageAlt || data.headline || "Title background image"}
              src={data.backgroundImage}
              imageSet={data.imageSet}
              hideControls
              css={`
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                ${data.filters &&
                css`
                  filter: ${getFilters(data.filters)};
                `}
                object-position: ${data?.backgroundPositioning?.desktop
                  ?.position || "center"};
                ${data?.backgroundPositioning?.tablet?.position &&
                tablet(css`
                  object-position: ${data?.backgroundPositioning?.tablet
                    ?.position || "center"};
                `)}
                ${data?.backgroundPositioning?.mobile?.position &&
                mobile(css`
                  object-position: ${data?.backgroundPositioning?.mobile
                    ?.position || "center"};
                `)}
              `}
            />
          </div>
        </div>
      )}
      <div
        css={`
          ${desktop(
            css`
              padding: 0 ${global?.sidePadding?.desktop || "calc(100% / 12)"};
              justify-content: center;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
            `
          )}
        `}
      >
        <div
          css={`
            flex: 0 0 66vh;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            ${tabletDown(
              css`
                margin-top: -80px;
              `
            )}
          `}
        >
          <Text
            t={"headline1"}
            tm="headline1"
            tt="display3"
            td="display1"
            data-tina-field={tinaField(data, "headline")}
            c={data?.textColor || (undefined as any)}
            style={{
              height: "fit-content",
              color: data.textColor || undefined,
              zIndex: 1,
            }}
          >
            <Markdown>{data.headline}</Markdown>
          </Text>
        </div>
        <DividerContainer c="elements/gray-2" />
        <div
          css={`
            flex: 0 0 33vh;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            ${desktop(
              css`
                flex-direction: row;
              `
            )}
          `}
        >
          <DesktopOnly>
            <Row mt={"3rem"}>
              <ExploreMore {...{ data }} />
              <Text
                as="div"
                pl={global?.sidePadding?.desktop || "calc(100vw / 12)"}
                t="body"
                data-tina-field={tinaField(data, "text")}
                c={data?.textColor || (undefined as any)}
              >
                <Markdown>{data.text}</Markdown>
              </Text>
            </Row>
          </DesktopOnly>
          <TabletOnly>
            <Row style={{ marginTop: "3rem", marginBottom: "2rem" }}>
              <ExploreMore {...{ data }} />
              <Text
                mb="3rem"
                as="div"
                pl={"calc(100vw / 8)"}
                t="body"
                data-tina-field={tinaField(data, "text")}
                c={data?.textColor || (undefined as any)}
              >
                <Markdown>{data.text}</Markdown>
              </Text>
            </Row>
          </TabletOnly>
          <MobileOnly>
            <Row>
              <Text
                as="div"
                t="body"
                mt="2rem"
                data-tina-field={tinaField(data, "text")}
                c={data?.textColor || (undefined as any)}
              >
                <Markdown>{data.text}</Markdown>
              </Text>
            </Row>
            <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
              <ExploreMore {...{ data }} />
            </Row>
          </MobileOnly>
        </div>
      </div>
    </Wrapper>
  );
}

export function ExploreMore({
  data,
}: {
  data: PageBlocksHero8 | PageBlocksHero15;
}) {
  const theme = useTheme();

  return (
    <Transition>
      <Text
        as="a"
        t="body"
        c={data?.textColor || (undefined as any)}
        data-tina-field={tinaField(data, "goto")}
        {...(data.goto ? { href: `#${data.goto}` } : {})}
        style={{ width: "fit-content", color: data.textColor || undefined }}
      >
        <svg
          style={{
            marginRight: "1rem",
            height: "14px",
            width: "14px",
            color: data?.textColor
              ? theme.colors[data?.textColor as keyof typeof theme.colors]
              : "white",
          }}
        >
          <use
            xlinkHref="/icons/arrow-down.svg#img"
            href="/icons/arrow-down.svg#img"
          ></use>
        </svg>
        Explore More
      </Text>
    </Transition>
  );
}

Hero8.blockName = "PageBlocksHero8";
