import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const TopWrapper = styled.div<{
    positioning?: PositioningParams | null;
  }>`
    ${(t) => t.positioning && position(t.positioning)}
  `,
  Wrapper = styled.div<{ global?: GlobalPartsFragment }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"} 0
            ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    overflow: hidden;
    grid: 2rem 2rem;
    > div {
      flex: 1 1 50%;
    }
    a {
      cursor: pointer;
    }
  `,
  Block = styled.div`
    flex: 0 0 50%;
    min-width: 230px;
    flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: center;
    position: relative;
    ${desktop(
      css`
        overflow: hidden;
      `
    )}
    ${tabletDown(
      css`
        overflow: visible;
      `
    )}
  `,
  LeftBlock = styled(Block)`
    ${desktop(
      css`
        padding-right: calc(100vw / 12);
      `
    )}
    ${tablet(
      css`
        padding-right: 2rem;
      `
    )}
    ${mobile(
      css`
        padding-right: 0;
      `
    )}
  `,
  InputBlock = styled.div`
    display: flex;
    flex-direction: row;
  `,
  Button = styled.button`
    ${desktop(
      css`
        margin-top: 3rem;
      `
    )}
    ${tabletDown(
      css`
        margin-top: 1rem;
        flex: 1 1 auto;
      `
    )}
  height: 40px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-1"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    ${(t) => t.theme.font.button}
  `,
  Input = styled.input`
    height: 40px;
    flex: 1 1 auto;
    padding: 0 1rem;
    ${desktop(
      css`
        margin-right: 1rem;
        margin-top: 3rem;
      `
    )}
    ${tabletDown(
      css`
        margin-right: 0rem;
        margin-top: 1rem;
      `
    )}
  display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    color: ${(t) => t.theme.colors["text/primary"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-1"]};
    }
    :active {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    :focus {
      outline: none;
    }
    ${(t) => t.theme.font.button}
  `,
  TitleText = styled(Text)`
    ${desktop(
      css`
        margin-top: 0%;
        margin-bottom: 2rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 1rem;
      `
    )}
  `,
  BodyText = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 2rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0rem;
      `
    )}
  `;
