import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  MobileOnly,
  TabletDown,
  TabletOnly,
  TabletUp,
  desktop,
  sizes,
  tabletDown,
} from "@/lib";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  Maybe,
  PageBlocksCards1,
  PageBlocksCards1Cards,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, Button, TitleText, Wrapper } from "./styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default function Cards1({
  data,
  global,
}: {
  data: PageBlocksCards1;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState(0);

  return (
    <Wrapper
      css={`
        justify-content: start;
        ${desktop(css`
          justify-content: ${(data?.cards?.length || 0) < 3
            ? "start"
            : "center"};
        `)}
      `}
      positioning={data.positioning}
      data-tina-field={data}
      id={data.id || undefined}
      global={global}
    >
      <TabletUp>
        <Text
          t={"headline1"}
          flex="0 1 100%"
          data-tina-field={tinaField(data, "title")}
          mt="0"
        >
          {data.title}
        </Text>
      </TabletUp>
      <MobileOnly>
        <Text
          t={"headline2"}
          mb={"0px"}
          flex="0 1 100%"
          data-tina-field={tinaField(data, "title")}
          mt="0"
        >
          {data.title}
        </Text>
      </MobileOnly>

      <DesktopOnly>
        {data.cards?.map((v, i) => (
          <Card key={i} {...{ v, i }} />
        ))}
      </DesktopOnly>
      <TabletOnly>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          style={{
            padding: "0 0",
            width: "242px",
            overflow: "visible",
            marginRight: "0rem",
            marginLeft: "0rem",
            height: "auto",
          }}
          slideStyle={{
            width: "242px",
            marginRight: "2rem",
            height: "auto",
          }}
          enableMouseEvents
        >
          {data.cards?.map((v, i) => (
            <Card key={i} {...{ v, i }} />
          ))}
        </SwipeableViews>
      </TabletOnly>
      <MobileOnly>
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={setIndex}
          interval={3000}
          animateTransitions={true}
          springConfig={{
            delay: "0.5s",
            easeFunction: "ease-in-out",
            duration: "2s",
          }}
          style={{
            padding: "0 0",
            width: "100%",
            overflow: "visible",
            marginRight: "0rem",
            marginLeft: "0rem",
            height: "auto",
          }}
          slideStyle={{
            width: "100%",
            marginRight: "0",
            height: "auto",
          }}
          enableMouseEvents
        >
          {data.cards?.map((v, i) => (
            <Card key={i} {...{ v, i }} />
          ))}
        </AutoPlaySwipeableViews>
      </MobileOnly>
    </Wrapper>
  );
}

function Card({ v, i }: { v: Maybe<PageBlocksCards1Cards>; i: number }) {
  return (
    <Block
      href={v?.buttonLink || ""}
      key={i}
      data-tina-field={v && tinaField(v)}
    >
      <ImageOrVideoSet
        data-tina-field={v && tinaField(v, "image")}
        src={v?.image || "/dummy.png"}
        fill
        sizes={sizes("75vw", "33vw", "33vw")}
        alt={v?.imageAlt || v?.title || ""}
        css={`
          position: relative !important;
        `}
        imageSet={v?.imageSet}
      />
      <div
        css={`
          ${desktop(
            css`
              padding: 2rem 9%;
            `
          )}
          ${tabletDown(
            css`
              padding: 1rem;
            `
          )}
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: start;
          justify-content: center;
        `}
      >
        <TitleText
          t={"headline3"}
          td="headline3"
          tt="headline4"
          tm="headline4"
          data-tina-field={v && tinaField(v, "title")}
        >
          {v?.title || ""}
        </TitleText>
        <Text
          t={"caption"}
          td="caption"
          tt="button"
          tm="button"
          mb="1rem"
          data-tina-field={v && tinaField(v, "description")}
        >
          <Markdown>{v?.description || ""}</Markdown>
        </Text>
        <DesktopOnly>
          <Button data-tina-field={v && tinaField(v, "buttonText")}>
            {v?.buttonText || ""}
          </Button>
        </DesktopOnly>
        <TabletDown>
          <div style={{ marginTop: "auto", height: "auto" }} />
        </TabletDown>
      </div>
    </Block>
  );
}
Cards1.blockName = "PageBlocksCards1";
