import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    flex-flow: row wrap;
    gap: 3rem 3rem;
  `,
  Block = styled.div`
    ${desktop(css`
      flex: 0 0 calc(33% - 2rem);
      flex-direction: column;
    `)}
    ${tablet(css`
      flex: 0 0 calc(6 / 8 * 100%);
    `)}
    ${mobile(css`
      flex: 0 0 100%;
    `)}
    padding: 0 0rem;

    display: flex;
    text-align: center;
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;
  `,
  TitleText = styled(Text)`
    ${tabletDown(
      css`
        margin-bottom: 0;
      `
    )}
  `;
