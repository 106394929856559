import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"};
        `
      )}
      ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: center;
    flex-flow: row wrap;
    gap: 3rem 3rem;
  `,
  Block = styled(SafeLink)`
    box-sizing: border-box;
    ${desktop(
      css`
        flex: 0 0 calc((100% / 3) - (6rem / 3));
        height: auto;
        min-height: 460px;
      `
    )}
    ${tablet(
      css`
        flex: 0 0 242px;
        width: 242px;
        min-height: 336px;
        height: 100%;
      `
    )}
  ${mobile(
      css`
        flex: 0 0 226px;
        width: 226px;
        min-height: 336px;
        height: 100%;
      `
    )}
    padding: 0 4rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    border: 1px solid ${(t) => t.theme.colors["text/primary"]};
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;
    > img {
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: scale(1);
      object-fit: cover;
      width: 100%;
      ${desktop(
        css`
          height: 47%;
        `
      )}
      ${tabletDown(
        css`
          height: 192px;
          min-height: 192px;
        `
      )}
    top: 0;
      left: 0;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.02);
      > img {
        transform: scale(1.02);
      }
    }
  `,
  Button = styled.button`
    //Learn More
    height: 40px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-2"]};
    :hover {
      border: 1px solid ${(t) => t.theme.colors["elements/gray-3"]};
      background-color: ${(t) => t.theme.colors["background/bg-0"]};
    }
    ${(t) => t.theme.font.button}
  `,
  TitleText = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 1rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0.5rem;
        margin-top: 0;
      `
    )}
  `;
