import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
          gap: 3rem 3rem;
          justify-content: flex-start;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
          gap: 1rem 2rem;
          justify-content: flex-start;
        `
      )}
  ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
          gap: 0 0;
          justify-content: center;
        `
      )}
  box-sizing: border-box;
    flex-flow: row wrap;
  `,
  Block = styled(SafeLink)`
    ${desktop(
      css`
        flex: 0 0 calc(33.3% - 2.3rem);
      `
    )}
    ${tablet(
      css`
        flex: 0 0 calc(50% - 1rem);
      `
    )}
  ${mobile(
      css`
        flex: 0 0 calc(100% - 1rem);
      `
    )}
  min-width: 230px;
    padding: 0 0rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;
    > div.image {
      width: 100%;
      padding-bottom: 72.22%;
      position: relative;
      > img {
        transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scale(1);
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      > div > img {
        transform: scale(1.02);
      }
    }
    > div.content {
      padding: 2rem 0 0 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: start;
      justify-content: start;
    }
  `,
  TextTitle = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 1rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0.5rem;
      `
    )}
  `,
  TextDescription = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 2rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0rem;
      `
    )}
  `;
