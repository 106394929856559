import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const TopWrapper = styled.div<{
    positioning?: PositioningParams | null;
  }>`
    ${(t) => t.positioning && position(t.positioning)}
  `,
  Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    min-height: 100vh;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    flex-wrap: wrap;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100vw / 12)"};
          flex-wrap: nowrap;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
          flex-wrap: wrap;
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
          flex-wrap: wrap;
        `
      )}
    box-sizing: border-box;
    justify-content: center;
    sup {
      ${(t) => t.theme.font.caption}
    }
  `,
  Heading = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    ${desktop(
      css`
        flex: 0 0 300px;
      `
    )}
    ${tabletDown(
      css`
        flex: 0 0 100%;
      `
    )}
  `,
  Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    ${tabletDown(
      css`
        margin-top: 2rem;
        flex: 1 1 100%;
      `
    )}
    /* margin-right: calc(100vw / 12); */
    color: ${(t) => t.theme.colors["text/primary"]};
    span {
      ${(props) => props.theme.font.body}
    }
    p {
      ${(props) => props.theme.font.body}
    }
    a {
      color: ${(t) => t.theme.colors["text/primary"]};
    }
    img {
      max-width: 100%;
      object-fit: cover;
    }
    > div > div > div > span {
      opacity: 1 !important;
    }
  `;
