import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"} 0
            ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
          gap: 3rem 3rem;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
          gap: 1rem;
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
          gap: 1rem;
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    flex-flow: row wrap;
  `,
  Block = styled.div`
    ${desktop(
      css`
        flex: 0 0 calc(33.3% - 2.3rem);
      `
    )}
    ${tablet(
      css`
        flex: 0 0 calc(50% - 1rem);
      `
    )}
  ${mobile(
      css`
        flex: 0 0 100%;
      `
    )}
  min-width: 230px;
    padding: 0 0rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;

    &:hover {
      > div > img {
        transform: scale(1.02);
      }
    }
  `,
  BlocksWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    box-sizing: border-box;
    justify-content: start;
    flex-flow: row wrap;
    ${desktop(
      css`
        gap: 3rem 3rem;
      `
    )}
    ${tabletDown(
      css`
        gap: 2rem;
      `
    )}
  `,
  DescriptionText = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 1rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: -0.5rem;
      `
    )}
  `;
