import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import { DesktopOnly, MobileOnly, TabletOnly, sizes, useLayout } from "@/lib";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  GlobalPartsFragment,
  PageBlocksGallery7,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { HeaderContainer, Wrapper } from "./styles";

export default function Gallery7({
  data,
  global,
}: {
  data: PageBlocksGallery7;
  global?: GlobalPartsFragment;
}) {
  const layout = useLayout();
  const [index, setIndex] = useState(layout === "desktop" ? 0 : 0);

  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
    >
      <HeaderContainer global={global}>
        <Text
          data-tina-field={tinaField(data, "titleText")}
          t="headline1"
          td="headline1"
          tt="headline1"
          tm="headline2"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="space-between"
          mt="0"
          mb="0"
        >
          <Markdown>{data?.titleText}</Markdown>
        </Text>
        <Text t="body" display="flex">
          <img
            src="/icons/arrow-left.svg"
            alt="Previous slide"
            style={{ cursor: "pointer" }}
            onClick={() =>
              setIndex(
                index === 0 ? (data.features?.length || 1) - 1 : index - 1
              )
            }
          />
          <img
            src="/icons/arrow-right.svg"
            alt="Next slide"
            style={{ cursor: "pointer" }}
            onClick={() =>
              setIndex(
                index === (data.features?.length || 0) - 1 ? 0 : index + 1
              )
            }
          />
        </Text>
      </HeaderContainer>
      <DesktopOnly>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          style={{
            padding: `0 ${
              global?.sidePadding?.desktop || "calc(100vw / 12)"
            } 0 0`,
            width: "400px",
            overflow: "visible",
            marginRight: "400px",
            marginLeft: global?.sidePadding?.desktop || "calc(100vw / 12)",
          }}
          slideStyle={{
            width: "400px",
            marginRight: "40px",
          }}
          enableMouseEvents
        >
          {data.features?.map((v, i) => (
            <SafeLink
              key={i}
              href={v?.imageLink}
              css={`
                padding-bottom: 138%;
                overflow: hidden;
                position: relative;
              `}
            >
              <ImageOrVideoSet
                data-tina-field={v && tinaField(v, "image")}
                css={`
                  width: 400px;
                  cursor: pointer;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  position: absolute !important;
                  object-fit: cover;
                `}
                fill
                sizes={sizes("75vw", "33vw", "33vw")}
                alt={v?.imageAlt || v?.title || ""}
                src={v?.image || "/dummy.png"}
                imageSet={v?.imageSet}
              />
            </SafeLink>
          ))}
        </SwipeableViews>
      </DesktopOnly>
      <TabletOnly>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          style={{
            padding: `0 ${global?.sidePadding?.tablet || "2rem"}`,
            width: "245px",
            overflow: "visible",
            marginRight: "400px",
            marginLeft: "0rem",
          }}
          slideStyle={{
            width: "245px",
            marginRight: global?.sidePadding?.tablet || "2rem",
          }}
          enableMouseEvents
        >
          {data.features?.map((v, i) => (
            <SafeLink
              key={i}
              href={v?.imageLink}
              css={`
                padding-bottom: 138%;
                overflow: hidden;
                position: relative;
              `}
            >
              <ImageOrVideoSet
                data-tina-field={v && tinaField(v, "image")}
                css={`
                  width: 245px;
                  cursor: pointer;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  position: absolute !important;
                  object-fit: cover;
                `}
                fill
                sizes={sizes("75vw", "33vw", "33vw")}
                alt={v?.imageAlt || v?.title || ""}
                src={v?.image || "/dummy.png"}
                imageSet={v?.imageSet}
              />
            </SafeLink>
          ))}
        </SwipeableViews>
      </TabletOnly>
      <MobileOnly>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          style={{
            padding: `0 ${global?.sidePadding?.mobile || "2rem"}`,
            width: "90%",
            overflow: "visible",
            marginRight: "400px",
            marginLeft: global?.sidePadding?.mobile || "2rem",
          }}
          slideStyle={{
            width: "90%",
            marginRight: global?.sidePadding?.mobile || "2rem",
          }}
          enableMouseEvents
        >
          {data.features?.map((v, i) => (
            <SafeLink
              key={i}
              href={v?.imageLink}
              css={`
                padding-bottom: 138%;
                overflow: hidden;
                position: relative;
              `}
            >
              <ImageOrVideoSet
                data-tina-field={v && tinaField(v, "image")}
                css={`
                  height: 100%;
                  cursor: pointer;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  position: absolute !important;
                  object-fit: cover;
                `}
                fill
                sizes={sizes("75vw", "33vw", "33vw")}
                alt={v?.imageAlt || v?.title || ""}
                src={v?.image || "/dummy.png"}
                imageSet={v?.imageSet}
              />
            </SafeLink>
          ))}
        </SwipeableViews>
      </MobileOnly>
    </Wrapper>
  );
}
Gallery7.blockName = "PageBlocksGallery7";
