import { Text } from "@/components/shared/Text";
import { Transition } from "@/components/shared/Transition";
import {
  PositioningParams,
  desktop,
  position,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";
import { Row } from "../styles";

export const OuterVideoWrapper = styled.div<{
    position?: "fixed" | "relative" | string;
    height?: number;
    filter?: string;
    color?: string;
    fullscreen?: boolean;
    $scale?: number;
    positioning?: PositioningParams | null;
  }>`
    width: 100vw;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    height: 100%;
    ${(t) => t.positioning && position(t.positioning)}
    ${desktop(css`
      left: 0rem;
      bottom: 0;
    `)}
    ${tabletDown(css`
      left: 0;
      bottom: 0;
    `)}
  ${(t) => css`
      ::before {
        content: "";
        background-color: ${t.color
          ? t.theme.colors[t.color as keyof typeof t.theme.colors]
          : t.theme.colors["background/bg-0"]};
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${t.filter &&
        css`
          filter: ${t.filter};
        `}
      }
    `}
  > *, ${Row} {
      z-index: 1;
    }
  `,
  TitleText = styled(Text)`
    height: fit-content;
    width: 100%;
    ${desktop(
      css`
        width: 50%;
      `
    )}
  `,
  BodyText = styled(Text)`
    ${desktop(
      css`
        width: 50%;
      `
    )}
    ${tabletDown(
      css`
        width: 100%;
      `
    )}
  `,
  CustomTransition = styled(Transition)<{ global?: GlobalPartsFragment }>`
    ${(t) =>
      desktop(
        css`
          flex: 0 0 ${t.global?.sidePadding?.desktop || "calc(100vw / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          flex: 0 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    display: flex;
    justify-content: center;
    align-items: flex-end;
    ${(t) =>
      tabletDown(
        css`
          margin-right: ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
  `,
  CustomRow = styled(Row)`
    ${tabletDown(
      css`
        margin-bottom: 3rem;
      `
    )}
  `;
