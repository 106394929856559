import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import { MobileOnly, TabletUp } from "@/lib";
import {
  GlobalPartsFragment,
  PageBlocksInformational9,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, DateText, SubWrapper, TopWrapper, Wrapper } from "./styles";
export default function Informational9({
  data,
  global,
}: {
  data: PageBlocksInformational9;
  global?: GlobalPartsFragment;
}) {
  const formatter: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  return (
    <TopWrapper
      positioning={data.positioning}
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
    >
      <MobileOnly>
        <Text
          data-tina-field={tinaField(data, "title")}
          t={"headline2"}
          flex="0 1 calc(50% - 1rem)"
          ml="2rem"
          display="flex"
          mt="0"
          style={{ zIndex: 1 }}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="space-between"
        >
          <Markdown>{data?.title}</Markdown>
        </Text>
      </MobileOnly>
      <Wrapper global={global}>
        <TabletUp>
          <SubWrapper>
            <Text
              data-tina-field={tinaField(data, "title")}
              t={"headline1"}
              td="headline1"
              tt="headline2"
              tm="headline2"
              flex="0 1 calc(50% - 1rem)"
              mb="2rem"
              display="flex"
              mt="0"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="space-between"
            >
              <Markdown>{data?.title}</Markdown>
            </Text>

            <SafeLink
              href={data.buttonLink}
              css={`
                margin-top: auto;
                margin-bottom: 3.5rem;
              `}
            >
              <Text
                data-tina-field={tinaField(data, "buttonText")}
                t="caption"
                mt="auto"
                mb="2rem"
              >
                {data.buttonText}
                <img
                  alt="Link to resource"
                  data-tina-field={tinaField(data, "buttonLink")}
                  src={
                    data.buttonLink?.includes("http")
                      ? "/icons/external-link.svg"
                      : "/icons/arrow-right-large.svg"
                  }
                  style={{ marginLeft: "1rem" }}
                />
              </Text>
            </SafeLink>
          </SubWrapper>
        </TabletUp>
        <div>
          {data.news?.map((v, i) => (
            <Block key={i} data-tina-field={v && tinaField(v)}>
              {v?.date && (
                <DateText
                  t="headline4"
                  mb="0"
                  mt="0"
                  data-tina-field={v && tinaField(v, "date")}
                >
                  {new Date(v?.date).toLocaleString("en-GB", formatter) || ""}
                </DateText>
              )}
              <Text
                t={"headline2"}
                td="headline2"
                tt="headline3"
                tm="headline3"
                mt="0"
                data-tina-field={v && tinaField(v, "title")}
              >
                {v?.title || ""}
              </Text>

              <SafeLink href={v?.link}>
                <Text
                  t="caption"
                  data-tina-field={v && tinaField(v, "linkName")}
                >
                  {v?.linkName || ""}
                  <img
                    data-tina-field={v && tinaField(v, "link")}
                    src={
                      v?.link?.includes("http")
                        ? "/icons/external-link.svg"
                        : "/icons/arrow-right-large.svg"
                    }
                  />
                </Text>
              </SafeLink>
              <Divider c="text/primary" mt="1.5rem" mb="2rem" />
            </Block>
          ))}
        </div>
      </Wrapper>
      <MobileOnly>
        <SafeLink href={data.buttonLink}>
          <Text
            data-tina-field={tinaField(data, "buttonText")}
            style={{ zIndex: 1 }}
            t="caption"
            mb="2rem"
            ml="2rem"
          >
            {data.buttonText}
            <img
              alt="Link to resource"
              data-tina-field={tinaField(data, "buttonLink")}
              src={
                data.buttonLink?.includes("http")
                  ? "/icons/external-link.svg"
                  : "/icons/arrow-right-large.svg"
              }
              style={{ marginLeft: "1rem" }}
            />
          </Text>
        </SafeLink>
      </MobileOnly>
    </TopWrapper>
  );
}
Informational9.blockName = "PageBlocksInformational9";
