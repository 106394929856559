import Head from "next/head";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { GlobalPartsFragment, PageQuery } from "tina/__generated__/types";
import { Layout } from "./Layout";

export const Page = (
  props: { children?: ReactNode } & PageQuery["page"] & {
      global?: GlobalPartsFragment;
    }
) => {
  const router = useRouter();
  // Let's remove the hash from the url if it's not needed
  useEffect(() => {
    const onHashChange = () => {
      // if path doesn't start from '/admin', remove the hash from the url
      if (!router.asPath.startsWith("/admin") && window.location.hash) {
        window.history.replaceState({}, "", window.location.href.split("#")[0]);
      }
    };
    router.events.on("hashChangeComplete", onHashChange);
    return () => {
      router.events.off("hashChangeComplete", onHashChange);
    };
  }, []);

  return (
    <>
      <Head>
        <meta
          property="og:image"
          key="og-image"
          content={
            "https://www.optindustries.com" +
            ((props?.meta?.ogImage?.split?.("?")?.[0] &&
              `/_next/image?url=${
                props?.meta?.ogImage?.split?.("?")?.[0]
              }&w=1200&q=75`) ||
              (props?.global?.meta?.ogImage?.split?.("?")?.[0] &&
                `/_next/image?url=${
                  props?.global?.meta?.ogImage?.split?.("?")?.[0]
                }&w=1200&q=75`) ||
              "/opt-default.jpg")
          }
        />
        <meta
          property="og:type"
          key="og-type"
          content={
            props?.meta?.ogType || props?.global?.meta?.ogType || "website"
          }
        />
        <meta
          property="og:url"
          key="og-url"
          content={`https://www.optindustries.com${router.asPath}`}
        />
        <meta
          property="og:title"
          key="og-title"
          content={
            props?.meta?.ogTitle ||
            props?.title ||
            props?.global?.meta?.ogTitle ||
            "OPT Industries"
          }
        />
        <meta
          property="og:site_name"
          key="og-site_name"
          content={
            props.meta?.ogSiteName ||
            props?.title ||
            props?.global?.meta?.ogSiteName ||
            "OPT Industries"
          }
        />
        <meta
          property="og:description"
          key="og-description"
          content={
            props?.meta?.ogDescription ||
            props?.description ||
            props?.global?.meta?.ogDescription ||
            "OPT Industries"
          }
        />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          key="viewport"
          content="minimum-scale=1, maximum-scale=5, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />

        <title>
          {props?.title || props?.global?.meta?.ogTitle || "OPT Industries"}
        </title>
        <meta
          name="description"
          key="description"
          content={
            props?.description ||
            props?.global?.meta?.ogDescription ||
            "OPT Industries"
          }
        />
        <meta
          name="keywords"
          key="keywords"
          content={
            props?.meta?.keywords?.join?.(", ") ||
            props?.global?.meta?.keywords?.join?.(", ") ||
            "Manifacturing, 3D Printing, OPT Industries"
          }
        />
        <meta
          name="author"
          key="author"
          content={
            props?.meta?.author ||
            props?.global?.meta?.author ||
            "OPT Industries"
          }
        />

        <meta
          name="twitter:card"
          key="twitter-card"
          content={
            props?.meta?.twitterCard ||
            props?.global?.meta?.twitterCard ||
            "summary_large_image"
          }
        />
        <meta
          name="twitter:site"
          key="twitter-site"
          content={
            props?.meta?.twitterSite ||
            props?.global?.meta?.twitterSite ||
            "@OPT_Industries"
          }
        />
        <meta
          name="twitter:description"
          key="twitter-description"
          content={
            props?.meta?.twitterDescription ||
            props?.description ||
            props?.global?.meta?.twitterDescription ||
            "OPT Industries"
          }
        />
        <meta
          name="twitter:title"
          key="twitter-title"
          content={
            props?.meta?.twitterTitle ||
            props?.title ||
            props?.global?.meta?.twitterTitle ||
            "OPT Industries"
          }
        />
        <meta
          name="twitter:image"
          key="twitter-image"
          content={
            "https://www.optindustries.com" +
            ((props?.meta?.ogImage?.split?.("?")?.[0] &&
              `/_next/image?url=${
                props?.meta?.ogImage?.split?.("?")?.[0]
              }&w=1200&q=75`) ||
              (props?.global?.meta?.ogImage?.split?.("?")?.[0] &&
                `/_next/image?url=${
                  props?.global?.meta?.ogImage?.split?.("?")?.[0]
                }&w=1200&q=75`) ||
              "/opt-default.jpg")
          }
        />
      </Head>
      {/* HEADER GOES HERE */}
      <Layout
        layout={props.global?.pageLayout}
        global={props?.global}
        pageId={props.id}
      >
        {props.children}
      </Layout>
    </>
  );
};
