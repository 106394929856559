import Markdown from "@/components/shared/Markdown";
import { desktop, mobile } from "@/lib";
import dynamic from "next/dynamic";
import { Suspense } from "react";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksVideoEmbed,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { TitleText, Wrapper } from "./styles";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const videoContainerStyle = css`
  position: relative !important;
  width: 100% !important;
  padding-top: 56.25%;
  ${mobile(
    css`
      width: 100vh !important;
      margin-left: -2rem;
      margin-right: -2rem;
      margin-top: 1rem;
      padding-top: calc(56.25% + (4rem * 0.5625));
    `
  )}
`;
export default function VideoEmbed({
  data,
  global,
}: {
  data: PageBlocksVideoEmbed;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      $positioning={data.positioning}
      $global={global}
    >
      {data?.title && (
        <>
          <TitleText
            data-tina-field={tinaField(data, "title")}
            mt={0}
            t={"headline1"}
            td="headline1"
            tt="headline3"
            tm="headline3"
            mb="0"
            css={`
              ${desktop(css`
                margin-bottom: 2rem;
              `)}
            `}
          >
            <Markdown>{data?.title}</Markdown>
          </TitleText>
        </>
      )}

      <Suspense fallback={<div css={videoContainerStyle}>Loading...</div>}>
        <div css={videoContainerStyle}>
          <ReactPlayer
            url={data?.videoEmbedLink}
            data-tina-field={tinaField(data, "videoEmbedLink")}
            width={"100%"}
            height={"100%"}
            loop={
              typeof data?.videoEmbedLoop === "boolean"
                ? data.videoEmbedLoop
                : false
            }
            muted={
              typeof data?.videoEmbedMuted === "boolean"
                ? data.videoEmbedMuted
                : true
            }
            playing={
              typeof data?.videoEmbedPlaying === "boolean"
                ? data.videoEmbedPlaying
                : false
            }
            controls={
              typeof data?.videoEmbedControls === "boolean"
                ? data.videoEmbedControls
                : false
            }
            css={`
              position: absolute;
              top: 0;
              left: 0;
            `}
          />
        </div>
      </Suspense>
    </Wrapper>
  );
}
VideoEmbed.blockName = "PageBlocksVideoEmbed";
