import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { DesktopOnly, MobileOnly, TabletDown, TabletUp, sizes } from "@/lib";
import {
  GlobalPartsFragment,
  PageBlocksInformational13,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, BodyText, TitleText, TopWrapper, Wrapper } from "./styles";

export default function Informational13({
  data,
  global,
}: {
  data: PageBlocksInformational13;
  global?: GlobalPartsFragment;
}) {
  return (
    <TopWrapper
      id={data.id || undefined}
      data-tina-field={tinaField(data)}
      positioning={data.positioning}
      css={`
        position: relative !important;
      `}
    >
      <MobileOnly>
        <Block>
          <Text
            t={"headline2"}
            mt={0}
            mr="calc(100vw / 12)"
            ml="2rem"
            data-tina-field={tinaField(data, "title")}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
        </Block>
      </MobileOnly>
      <Wrapper global={global}>
        <TabletUp>
          <Block style={{ position: "relative" }}>
            <TitleText
              t={"headline2"}
              td="headline1"
              tt="headline2"
              mt={0}
              mr="calc(100vw / 12)"
              data-tina-field={tinaField(data, "title")}
            >
              <Markdown>{data?.title}</Markdown>
            </TitleText>
            <DesktopOnly>
              {data.imageLeft?.image && (
                <ImageOrVideoSet
                  sizes={sizes("50vw", "50vw", "50vw")}
                  fill
                  src={data.imageLeft.image}
                  alt={
                    data.imageLeft.imageAlt ||
                    data.imageLeft.imageAlt ||
                    data.title ||
                    ""
                  }
                  css={`
                    position: relative !important;
                    object-fit: contain;
                    height: unset !important;
                    margin-top: auto;
                  `}
                  speed={data.imageLeft.speed}
                  imageSet={data.imageLeft.imageSet}
                />
              )}
            </DesktopOnly>
          </Block>
        </TabletUp>
        <Block>
          <BodyText
            t="body"
            c="text/primary"
            data-tina-field={tinaField(data, "text")}
          >
            <Markdown>{data?.text}</Markdown>
          </BodyText>
          <DesktopOnly>
            {data.imageRight?.image && (
              <ImageOrVideoSet
                fill
                sizes={sizes("50vw", "50vw", "50vw")}
                src={data.imageRight?.image}
                alt={data.imageRight.imageAlt || data.title || ""}
                css={`
                  position: relative !important;
                `}
                speed={data.imageRight.speed}
                imageSet={data.imageRight.imageSet}
              />
            )}
          </DesktopOnly>
        </Block>
      </Wrapper>
      <TabletDown>
        {data.imageLeft?.image && (
          <ImageOrVideoSet
            fill
            sizes={sizes("100vw", "100vw", "100vw")}
            alt={data.imageLeft.imageAlt || data.title || ""}
            src={data.imageLeft.image}
            imageSet={data.imageLeft.imageSet}
            speed={data.imageLeft.speed}
            css={`
              position: relative !important;
              margin: 0.5rem 0 2rem 0;
            `}
          />
        )}
      </TabletDown>
      <TabletDown>
        {data.imageRight?.image && (
          <ImageOrVideoSet
            fill
            sizes={sizes("100vw", "100vw", "100vw")}
            alt={data.imageRight.imageAlt || data.title || ""}
            src={data.imageRight.image}
            imageSet={data.imageRight.imageSet}
            speed={data.imageRight.speed}
            css={`
              position: relative !important;
              margin: ${data.imageLeft?.image ? "-1rem" : "0.5rem"} 0 0 0;
            `}
          />
        )}
      </TabletDown>
    </TopWrapper>
  );
}
Informational13.blockName = "PageBlocksInformational13";
