import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { MobileOnly, TabletUp, sizes } from "@/lib";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  GlobalPartsFragment,
  Maybe,
  PageBlocksFeatures6,
  PageBlocksFeatures6Features,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, TextDescription, TextTitle, Wrapper } from "./styles";

export default function Features6({
  data,
  global,
}: {
  data: PageBlocksFeatures6;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState(0);

  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <Text
        t={"headline1"}
        td="headline1"
        tt="headline2"
        tm="headline2"
        flex="0 1 100%"
        mt="0"
        data-tina-field={tinaField(data, "title")}
      >
        {data.title}
      </Text>
      <TabletUp>
        {data.features?.map((v, i) => (
          <Feature key={i} {...{ v, i }} />
        ))}
      </TabletUp>
      <MobileOnly>
        <SwipeableViews
          index={index}
          onChangeIndex={setIndex}
          style={{
            padding: "0 0",
            width: "calc(100% + 2rem)",
            overflow: "visible",
            marginRight: "0rem",
            marginLeft: "0rem",
          }}
          slideStyle={{
            width: "calc(100% - 2rem)",
            marginRight: "2rem",
          }}
          enableMouseEvents
        >
          {data.features?.map((v, i) => (
            <Feature key={i} {...{ v, i }} />
          ))}
        </SwipeableViews>
        <div
          style={{
            display: "flex",
            padding: "0rem",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.features?.map((v, i) => (
            <div
              key={i}
              onClick={() => setIndex(i)}
              style={{
                width: index === i ? "11px" : "10px",
                height: index === i ? "11px" : "10px",
                borderRadius: "50%",
                cursor: "pointer",
                margin: "0 0.5rem",
                backgroundColor: index === i ? "white" : "#bbb",
              }}
            ></div>
          ))}
        </div>
      </MobileOnly>
    </Wrapper>
  );
}

function Feature({
  v,
  i,
}: {
  v: Maybe<PageBlocksFeatures6Features>;
  i: number;
}) {
  return (
    <Block
      href={v?.imageLink || ""}
      key={i}
      data-tina-field={v && tinaField(v)}
    >
      <div className="image" data-tina-field={v && tinaField(v, "image")}>
        <ImageOrVideoSet
          fill
          sizes={sizes("100vw", "50vw", "33vw")}
          src={v?.image || "/dummy.png"}
          alt={v?.imageAlt || v?.description}
          imageSet={v?.imageSet}
        />
      </div>
      <div className="content">
        <TextTitle
          t={"headline3"}
          td="headline3"
          tt="headline4"
          tm="headline4"
          data-tina-field={v && tinaField(v, "title")}
        >
          {v?.title || ""}
        </TextTitle>
        <TextDescription
          t={"caption"}
          td="caption"
          tt="button"
          tm="button"
          data-tina-field={v && tinaField(v, "description")}
        >
          <Markdown>{v?.description}</Markdown>
        </TextDescription>
      </div>
    </Block>
  );
}
Features6.blockName = "PageBlocksFeatures6";
