import styled from 'styled-components'

export const 
Transition = styled.div`
  > *{
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
    transform: translate(0px, 0px);
    cursor: pointer;
    :hover{
      transform: translate(0px, 2px);
    }
    :active{
      transform: translate(0px, 3px);
    }
  }
`