export { default as Informational3 } from "./Informational3";
export { default as Informational4 } from "./Informational4";
export { default as Informational5 } from "./Informational5";
export { default as Informational8 } from "./Informational8";
export { default as Informational9 } from "./Informational9";
export { default as Informational10 } from "./Informational10";
export { default as Informational12 } from "./Informational12";
export { default as Informational13 } from "./Informational13";
export { default as Informational14 } from "./Informational14";
export { default as Informational15 } from "./Informational15";
export { default as Informational16 } from "./Informational16";
export { default as Informational17 } from "./Informational17";
