import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import { getFilters } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksVideo2,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Wrapper } from "./styles";

export default function Video2({
  data,
  global,
}: {
  data: PageBlocksVideo2;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      color={data.backgroundColor || undefined}
      positioning={data.positioning}
      fullWidth={!!data.fullWidth}
      global={global}
    >
      <ImageOrVideoSet
        data-tina-field={tinaField(data, "video")}
        src={data.video}
        imageSet={data.videoSet}
        alt={data.videoAlt || "Video"}
        css={`
          position: relative !important;
          ${data.filters &&
          css`
            filter: ${getFilters(data.filters)};
          `}
        `}
      ></ImageOrVideoSet>
    </Wrapper>
  );
}
Video2.blockName = "PageBlocksVideo2";
