import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { MobileOnly, TabletUp, desktop, sizes } from "@/lib";
import { getFilters } from "@/lib/filters";
import { css, useTheme } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksHero15,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Divider, Text } from "../../../shared/Text";
import { ExploreMore } from "../Hero8";
import { Row, Wrapper } from "../styles";
import { BodyText, CustomRow, CustomTransition } from "./styles";

export default function Hero15({
  data,
  global,
}: {
  data: PageBlocksHero15;
  global?: GlobalPartsFragment;
}) {
  const theme = useTheme();

  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      color={
        data.backgroundColor
          ? theme.colors[data.backgroundColor as keyof typeof theme.colors]
          : undefined
      }
      filter={data.filters ? getFilters(data.filters) : undefined}
      positioning={data.positioning}
      style={{
        alignItems: "flex-end",
        justifyContent: "flex-end",
      }}
    >
      {data.backgroundImage && (
        <ImageOrVideoSet
          fill
          sizes={sizes("100vw", "100vw", "100vw")}
          priority
          alt={data.imageAlt || data.headline || "Title background image"}
          imageSet={data.imageSet}
          src={data.backgroundImage}
          hideControls
          css={`
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
          `}
        />
      )}

      <CustomRow>
        <TabletUp>
          <CustomTransition global={global}>
            <SafeLink
              data-tina-field={tinaField(data, "goto")}
              href={data.goto && `#${data.goto}`}
              css={`
                width: fit-content;
              `}
            >
              <svg
                style={{
                  marginBottom: "2rem",
                  height: "34px",
                  width: "34px",
                  color: data?.textColor
                    ? theme.colors[data?.textColor as keyof typeof theme.colors]
                    : "white",
                }}
              >
                <use
                  xlinkHref="/icons/arrow-down.svg#img"
                  href="/icons/arrow-down.svg#img"
                ></use>
              </svg>
            </SafeLink>
          </CustomTransition>
        </TabletUp>
        <div
          css={desktop(
            css`
              padding-right: ${global?.sidePadding?.desktop ||
              "calc(100vw / 12)"};
            `
          )}
        >
          <Text
            t={"headline1"}
            tm="headline1"
            tt="display3"
            td="display3"
            data-tina-field={tinaField(data, "headline")}
            c={data?.textColor || (undefined as any)}
          >
            <Markdown>{data.headline}</Markdown>
          </Text>
          <MobileOnly>
            <Divider c="elements/gray-2" mt="3rem" mb="0rem" />
          </MobileOnly>

          <BodyText
            as="div"
            t="body"
            mt="2rem"
            data-tina-field={tinaField(data, "text")}
            c={data?.textColor || (undefined as any)}
            style={{ width: "50%" }}
          >
            <Markdown>{data.text}</Markdown>
          </BodyText>

          <MobileOnly>
            <Row style={{ marginTop: "3rem", marginBottom: "1rem" }}>
              <ExploreMore {...{ data }} />
            </Row>
          </MobileOnly>
        </div>
      </CustomRow>
    </Wrapper>
  );
}
Hero15.blockName = "PageBlocksHero15";
