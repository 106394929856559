import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    ${(t) => t.positioning && position(t.positioning)}
    width: 100vw;
    max-width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
  box-sizing: border-box;
    justify-content: center;
    flex-flow: row wrap;
    gap: 3rem 0;
  `,
  Block = styled.div`
    flex: 0 0 33%;
    min-width: 300px;
    padding: 0 4rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    ${tabletDown(
      css`
        flex: 1 1 100%;
      `
    )}
  `;
