import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    flex-flow: row wrap;
    gap: 3rem 3rem;
  `,
  Block = styled(SafeLink)`
    flex: 0 0 calc(25% - 2.5rem);
    padding: 0 0rem;
    flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0;
    > div.image {
      cursor: pointer;
      position: relative;
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
      padding: 0.5rem;
      border: 0.5px solid ${(t) => t.theme.colors["text/secondary"]};
      > img {
        transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scale(1);
        object-fit: cover;
        position: relative;
        width: 100%;
        height: 100%;
      }
      &:hover {
        > img {
          transform: scale(1.2);
        }
      }
    }

    > div.content {
      ${desktop(
        css`
          padding: 2rem 0 0 0;
        `
      )}
      ${tabletDown(
        css`
          padding: 0rem 0;
        `
      )}
    height: 100%;
      display: flex;
      flex-direction: column;
      text-align: start;
      justify-content: start;
    }
  `,
  TitleText = styled(Text)`
    ${tabletDown(
      css`
        margin-bottom: 0;
      `
    )}
  `;
