import { ImageOrVideo } from "@/components/shared/ImageOrVideo";
import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import { MobileOnly, desktop, sizes, tabletDown } from "@/lib";
import { useEffect, useRef, useState } from "react";
import { css } from "styled-components";
import { client } from "tina/__generated__/client";
import {
  ArticleFilter,
  GlobalPartsFragment,
  InputMaybe,
  PageBlocksBlog1,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, BlocksWrapper, Wrapper } from "./styles";

function capitalizeWord([first, ...rest]: any, lc?: any) {
  return (
    first?.toUpperCase() + (lc ? rest?.join("")?.toLowerCase() : rest?.join(""))
  );
}

export type ArticlesData = Awaited<
  ReturnType<(typeof client)["queries"]["articleConnectionQuery"]>
>;

export async function getArticles(
  data: PageBlocksBlog1,
  filter: { categories: (string | null)[]; before?: string; last?: number }
): Promise<ReturnType<(typeof client)["queries"]["articleConnectionQuery"]>> {
  const queryFilter: InputMaybe<ArticleFilter> = {
    categories: {
      category: {
        category: {
          name: {
            in:
              data.categories
                ?.filter(
                  (v) =>
                    v?.category?.name &&
                    filter.categories.includes(v?.category?.name || "")
                )
                ?.map((v) => v?.category?.name as string) || [],
          },
        },
      },
    },
  };
  const articlesResponse = await client.queries.articleConnectionQuery({
    sort: "createdAt",
    filter: data.categories?.length ? queryFilter : {},
    ...(filter.before ? { before: filter.before } : {}),
    last: Math.max(data.limit || 0, 3),
  });

  return articlesResponse;
}

export default function Blog1({
  data,
  articles,
  global,
}: {
  data: PageBlocksBlog1;
  articles?: Awaited<ReturnType<typeof getArticles>> | undefined;
  global?: GlobalPartsFragment;
}) {
  const [articleQuery, setArticleQuery] = useState<ArticlesData | undefined>(
    articles
  );
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    categories: data.categories?.map((v) => v?.category?.name) as string[],
    before: undefined as string | undefined,
    last: Math.max(data.limit || 0, 3) as number | undefined,
    draft: { eq: false },
  });

  const previousFilter = useRef<string[]>([]);

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);

      const articlesResponse = await getArticles(data, filter);
      setArticleQuery(articlesResponse);
      setLoading(false);
    };
    fetchContent();
  }, [data.limit, data.categories?.join(), filter]);

  const more =
    articleQuery?.data.articleConnection.pageInfo.hasPreviousPage ||
    articleQuery?.data.articleConnection.pageInfo.hasNextPage;
  // if (isLoading) return <Text t='headline3'>Loading...</Text>
  // if (!data) return <Text t='headline3'>No articles</Text>
  const formatter: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  return (
    <Wrapper
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
      data-tina-field={data}
    >
      {data.title && (
        <Text
          t={"headline1"}
          td="headline1"
          tt="headline2"
          tm="headline2"
          mt={0}
          flex="0 1 100%"
          data-tina-field={tinaField(data, "title")}
        >
          {data.title}
        </Text>
      )}
      {data.visible && (
        <Text
          t="body"
          flex="0 1 100%"
          data-tina-field={tinaField(data, "visible")}
        >
          <>
            {(data?.categories?.length || 0) > 1 && (
              <>
                {data.categories?.map((c, i) => (
                  <span
                    key={i}
                    style={{
                      cursor: "pointer",
                      color: filter.categories?.includes(
                        c?.category?.name || ""
                      )
                        ? "inherit"
                        : "grey",
                    }}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        categories: filter.categories?.includes(
                          c?.category?.name || ""
                        )
                          ? filter.categories.filter(
                              (v) => v !== c?.category?.name
                            )
                          : [
                              ...filter.categories,
                              ...(c?.category?.name ? [c?.category?.name] : []),
                            ],
                      })
                    }
                  >
                    {filter.categories?.includes(c?.category?.name || "")
                      ? "-"
                      : "+"}{" "}
                    {capitalizeWord(c?.category?.name || "")} /{" "}
                  </span>
                ))}
              </>
            )}
            <MobileOnly>
              <br />
            </MobileOnly>
            <span
              style={{
                cursor: "pointer",
                color: page !== 0 ? "inherit" : "grey",
              }}
              onClick={() => {
                if (page !== 0 && previousFilter.current) {
                  setFilter({
                    ...filter,
                    before:
                      previousFilter.current[previousFilter.current.length - 2],
                  });
                  previousFilter.current.pop();
                  setPage(page - 1);
                }
              }}
            >
              Previous Page /{" "}
            </span>
            <span
              style={{ cursor: "pointer", color: more ? "inherit" : "grey" }}
              onClick={() => {
                if (more) {
                  previousFilter.current = [
                    ...previousFilter.current,
                    articleQuery?.data?.articleConnection?.pageInfo?.endCursor,
                  ];
                  setFilter({
                    ...filter,
                    before:
                      articleQuery?.data?.articleConnection?.pageInfo
                        ?.endCursor || "",
                  });
                  setPage(page + 1);
                }
              }}
            >
              Next Page /{" "}
            </span>
          </>
        </Text>
      )}
      <BlocksWrapper data-tina-field={tinaField(data, "limit")}>
        {articleQuery?.data.articleConnection.edges
          ?.sort(
            (b, a) =>
              a?.node?.createdAt?.localeCompare?.(b?.node?.createdAt || "") || 0
          )
          ?.map((v, i) => {
            const article = v?.node;

            return (
              <Block data-tina-field={tinaField(data, "categories")} key={i}>
                <SafeLink
                  key={i}
                  href={"/articles/" + article?._sys.breadcrumbs.join("/")}
                  css={`
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                    &:hover {
                      > div > img {
                        transform: scale(1.01);
                      }
                      > div > h3 {
                        text-decoration: underline;
                      }
                    }
                  `}
                >
                  <div
                    css={`
                      width: 100%;
                      padding-bottom: 72.22%;
                      position: relative;
                    `}
                  >
                    <ImageOrVideo
                      src={article?.image || "/dummy.png"}
                      data-tina-field={article && tinaField(article, "image")}
                      fill
                      sizes={sizes("100vw", "50vw", "33vw")}
                      alt={article?.imageAlt || article?.title || ""}
                      css={`
                        position: absolute !important;
                        width: 100%;
                        object-fit: cover;
                        transition: transform 0.5s
                          cubic-bezier(0.165, 0.84, 0.44, 1);
                        transform: scale(1);
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        object-position: center center;
                      `}
                    />
                  </div>
                  <div
                    css={`
                      ${desktop(
                        css`
                          padding: 2rem 0 0 0;
                        `
                      )}
                      ${tabletDown(
                        css`
                          padding: 1rem 0 0 0;
                        `
                      )}
                      display: flex;
                      flex-direction: column;
                      text-align: start;
                      justify-content: center;
                      flex: 1 1 auto;
                    `}
                  >
                    <Text
                      t="headline3"
                      mb="1rem"
                      mt="0"
                      data-tina-field={article && tinaField(article, "title")}
                    >
                      {article?.title || ""}
                    </Text>

                    {article?.createdAt && (
                      <Text
                        t="caption"
                        data-tina-field={tinaField(article, "createdAt")}
                        c="text/secondary"
                        mb="0"
                        css={`
                          margin-top: auto;
                        `}
                      >
                        {new Date(article.createdAt).toLocaleString(
                          "en-GB",
                          formatter
                        ) || ""}
                      </Text>
                    )}
                  </div>
                </SafeLink>
              </Block>
            );
          })}
        {!articleQuery?.data?.articleConnection?.edges ||
          (articleQuery?.data.articleConnection.edges.length === 0 && (
            <Text t="headline3" mb="4rem">
              No articles here yet...
            </Text>
          ))}
      </BlocksWrapper>
    </Wrapper>
  );
}
Blog1.blockName = "PageBlocksBlog1";
