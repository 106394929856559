import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import { MobileOnly, TabletUp, desktop } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksInformational8,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, BodyText, TitleText, TopWrapper, Wrapper } from "./styles";

export default function Informational8({
  data,
  global,
}: {
  data: PageBlocksInformational8;
  global?: GlobalPartsFragment;
}) {
  return (
    <TopWrapper
      positioning={data.positioning}
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
    >
      <MobileOnly>
        <Block>
          <Text
            t={"headline2"}
            mb="1rem"
            mr="calc(100vw / 12)"
            ml="2rem"
            mt="0"
            data-tina-field={tinaField(data, "title")}
          >
            <Markdown>{data?.title}</Markdown>
          </Text>
        </Block>
      </MobileOnly>
      <Wrapper global={global}>
        <TabletUp>
          <Block>
            <TitleText
              t={"headline2"}
              td="headline1"
              tt="headline2"
              mt={0}
              mr="calc(100vw / 12)"
              data-tina-field={tinaField(data, "title")}
              css={desktop(css`
                margin-right: calc(100vw / 12);
              `)}
            >
              <Markdown>{data?.title}</Markdown>
            </TitleText>
          </Block>
        </TabletUp>
        <Block>
          <BodyText
            t="body"
            c="text/primary"
            data-tina-field={tinaField(data, "text")}
            css={desktop(css`
              margin-left: calc(100vw / 12);
            `)}
          >
            <Markdown>{data?.text}</Markdown>
          </BodyText>
        </Block>
      </Wrapper>
    </TopWrapper>
  );
}
Informational8.blockName = "PageBlocksInformational8";
