import { Divider } from "@/components/shared/Text";
import { PositioningParams, desktop, position, tabletDown } from "@/lib";
import styled, { css } from "styled-components";
import { Row } from "../styles";

export const OuterVideoWrapper = styled.div<{
    position?: "fixed" | "relative" | string;
    height?: number;
    filter?: string;
    color?: string;
    fullscreen?: boolean;
    $scale?: number;
    backgroundPosition?: string;
    positioning?: PositioningParams | null;
  }>`
    width: 100vw;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    height: 100%;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) => css`
      ::before {
        content: "";
        background-color: ${t.color
          ? t.theme.colors[t.color as keyof typeof t.theme.colors]
          : "transparent"};
        background-position: ${t.backgroundPosition || "center"};
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${t.filter &&
        css`
          filter: ${t.filter};
        `}
      }
    `}
  > *, ${Row} {
      z-index: 1;
    }
  `,
  DividerContainer = styled(Divider)`
    ${desktop(
      css`
        margin-top: 0rem;
        margin-bottom: 0rem;
      `
    )}
    ${tabletDown(
      css`
        margin-top: 1rem;
        margin-bottom: 0rem;
      `
    )}
  `;
