import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import {
  DesktopOnly,
  TabletDown,
  desktop,
  mobile,
  sizes,
  tabletDown,
} from "@/lib";
import { AnimationControls, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  Maybe,
  PageBlocksInformational17,
  PageBlocksInformational17Features,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import {
  FeatureBlock,
  HorizontalArrow,
  ImageContainer,
  TextTitle,
  TitleText,
  VerticalArrow,
  Wrapper,
} from "./styles";

export default function Informational17({
  data,
  global,
}: {
  data: PageBlocksInformational17;
  global?: GlobalPartsFragment;
}) {
  const heightControl = useAnimation();
  const widthControl = useAnimation();
  const opacityControl = useAnimation();
  const [refH, inViewH] = useInView();
  const [refV, inViewV] = useInView();

  useEffect(() => {
    if (inViewH) {
      widthControl.start({ width: "100%" });
      opacityControl.start({ opacity: 1 });
    }
    if (inViewV) {
      heightControl.start({ height: "100%" });
      opacityControl.start({ opacity: 1 });
    }
  }, [heightControl, widthControl, inViewH, inViewV]);
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      {data?.title && (
        <>
          <TitleText
            data-tina-field={tinaField(data, "title")}
            mt="0"
            mb="0"
            t={"headline1"}
            td="headline1"
            tt="headline2"
            tm="headline2"
            css={`
              margin-left: 0;
              ${tabletDown(
                css`
                  margin-bottom: 2rem;
                `
              )}
            `}
          >
            <Markdown>{data?.title}</Markdown>
          </TitleText>
        </>
      )}

      <div
        css={`
          width: 100%;
          display: flex;
          ${desktop(
            css`
              gap: 3rem;
            `
          )}
          ${tabletDown(
            css`
              gap: 1rem;
            `
          )}
          position: relative;
          flex-wrap: wrap;
        `}
      >
        {data.features?.map((v, i) => (
          <Feature key={i} {...{ v, i, opacityControl }} />
        ))}
        <DesktopOnly>
          <HorizontalArrow
            ref={refH}
            animate={widthControl}
            initial={{ width: 0 }}
            transition={{ duration: 3.5 }}
          >
            <div
              css={`
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: grey;
                transform: translate(-50%, calc(-50% - 1px));
              `}
            />
            <svg
              width="7.5px"
              height="12px"
              fill="none"
              css={`
                position: absolute;
                right: 0px;
                transform: translate(0px, calc(-150% + 1px));
              `}
            >
              <path
                stroke="grey"
                strokeWidth="2"
                strokeLinejoin="miter"
                d="M0 0L6 6L0 12"
              />
            </svg>
          </HorizontalArrow>
        </DesktopOnly>
        <TabletDown>
          <VerticalArrow
            ref={refV}
            animate={heightControl}
            initial={{ height: 0 }}
            transition={{ duration: 3 }}
          >
            <div
              css={`
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: grey;
                transform: translate(-50%, calc(-50% - 1px));
              `}
            />
            <svg
              width="7.5px"
              height="12px"
              fill="none"
              css={`
                position: absolute;
                bottom: 0px;
                transform: translate(calc(-50% - 1px), 2px) rotate(90deg);
              `}
            >
              <path
                stroke="grey"
                strokeWidth="2"
                strokeLinejoin="miter"
                d="M0 0L6 6L0 12"
              />
            </svg>
          </VerticalArrow>
        </TabletDown>
      </div>
    </Wrapper>
  );
}
Informational17.blockName = "PageBlocksInformational17";

function Feature({
  v,
  i,
  opacityControl,
}: {
  v: Maybe<PageBlocksInformational17Features>;
  i: number;
  opacityControl?: AnimationControls;
}) {
  return (
    <FeatureBlock
      key={i}
      data-tina-field={v && tinaField(v)}
      animate={opacityControl}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.8, delay: i }}
    >
      <ImageContainer data-tina-field={v && tinaField(v, "image")}>
        <ImageOrVideoSet
          fill
          sizes={sizes("100vw", "100vw", "33vw")}
          alt={v?.imageAlt || v?.title || ""}
          src={v?.image || "/dummy.png"}
          imageSet={v?.imageSet}
          css={`
            position: relative !important;
          `}
        />
      </ImageContainer>
      <div
        css={`
          padding: 2rem 0 0 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: start;
          justify-content: start;
          ${mobile(css`
            padding: 0 2rem 0 0;
          `)}
        `}
      >
        <TextTitle
          t={"headline3"}
          td="headline3"
          tt="headline4"
          tm="headline4"
          data-tina-field={v && tinaField(v, "title")}
          mb="0"
        >
          {v?.title || ""}
        </TextTitle>
      </div>
    </FeatureBlock>
  );
}
