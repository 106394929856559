import { colorTypes, Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
          justify-content: center;
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"};
          justify-content: flex-start;
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"};
          justify-content: flex-start;
        `
      )}
    box-sizing: border-box;
    flex-flow: row wrap;
    gap: 1rem 3rem;
    overflow: hidden;
    > img {
      width: 100%;
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: scale(1);
      cursor: pointer;
      :hover {
        transform: scale(1.01);
      }
    }
  `,
  Block = styled.div`
    cursor: pointer;
    ${desktop(
      css`
        box-sizing: border-box;
        flex: 0 0 calc(25% - 2.5rem);
      `
    )}
    ${tablet(
      css`
        flex: 0 0 calc(50% - 2rem);
      `
    )}
    ${mobile(
      css`
        flex: 0 0 calc(100% - 1rem);
      `
    )}
    :hover {
      .divider {
        transform: scaleY(2);
        transform-origin: center right;
      }
      svg {
        transform: scale(2);
        transform-origin: center right;
      }
    }
    flex-direction: column;
    display: flex;
    text-align: center;
    position: relative;
    overflow: hidden;
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: start;
      justify-content: center;
    }
  `,
  LineBreak = styled.div`
    flex-basis: 100%;
    width: 0;
  `,
  Button = styled.button`
    display: block;
    height: 40px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    cursor: pointer;
    margin-right: auto;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-1"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    ${(t) => t.theme.font.button}
  `,
  TitleText = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 2rem;
        flex: 0 1 calc(100%);
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0;
        flex: 0 1 calc(100%);
      `
    )}
  `,
  BodyText = styled(Text)`
    ${desktop(
      css`
        flex: 0 0 calc(50% - 2rem);
      `
    )}
  `,
  Divider = styled.div<{ c?: colorTypes }>`
    border-top: 1px solid ${(t) => t.theme.colors[t?.c || "text/primary"]};
    width: calc(100% - 1px);
  `;
