import { desktop, mobile, position, PositioningParams, tablet } from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{ positioning?: PositioningParams | null }>`
    width: 100vw;
    ${(t) => t.positioning && position(t.positioning)}
    ${desktop(
      css`
        min-height: 100vh;
      `
    )}
    position: relative;
    display: flex;
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    flex-direction: column;
  `,
  HeaderContainer = styled.div<{ global?: GlobalPartsFragment }>`
    display: flex;
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100vw / 12)"}
            2rem ${t?.global?.sidePadding?.desktop || "calc(100vw / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"} 2rem
            ${t?.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"} 2rem
            ${t?.global?.sidePadding?.mobile || "2rem"};
        `
      )}
  justify-content: space-between;
    align-items: center;
  `;
