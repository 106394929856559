import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  MobileOnly,
  TabletOnly,
  getPlainText,
  sizes,
} from "@/lib";
import {
  GlobalPartsFragment,
  PageBlocksGallery5,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Block, Container, HeaderBlock, Wrapper } from "./styles";
export default function Gallery5({
  data,
  global,
}: {
  data: PageBlocksGallery5;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <HeaderBlock>
        <Text
          t="headline1"
          data-tina-field={tinaField(data, "titleText")}
          mt="0"
        >
          <Markdown>{data?.titleText}</Markdown>
        </Text>
        <Text
          t="body"
          data-tina-field={tinaField(data, "description")}
          mb="2rem"
        >
          <Markdown>{data?.description}</Markdown>
        </Text>
        <DesktopOnly>
          <Block
            style={{ width: "360px", marginTop: "auto", marginLeft: "auto" }}
          >
            <div
              className="image"
              data-tina-field={tinaField(data, "largeImage")}
            >
              <ImageOrVideoSet
                fill
                sizes={sizes("75vw", "75vw", "75vw")}
                src={data.largeImage?.image || "/dummy.png"}
                alt={data.largeImage?.imageAlt || getPlainText(data.titleText)}
                imageSet={data.largeImage?.imageSet}
              />
            </div>
          </Block>
        </DesktopOnly>
      </HeaderBlock>
      <Container>
        <MobileOnly>
          <Block
            style={{
              flex: "0 0 144px",
              minWidth: "unset",
              maxHeight: "0 0 144px",
              marginTop: "auto",
            }}
          >
            <div
              className="image"
              data-tina-field={tinaField(data, "largeImage")}
            >
              <ImageOrVideoSet
                fill
                sizes={sizes("75vw", "75vw", "75vw")}
                src={data.largeImage?.image || "/dummy.png"}
                alt={data.largeImage?.imageAlt || getPlainText(data.titleText)}
                imageSet={data.largeImage?.imageSet}
              />
            </div>
          </Block>
          <Block style={{ flex: "0 0 314px", minHeight: "0 0 314px" }}>
            <div
              className="image"
              data-tina-field={tinaField(data, "largeImage")}
              style={{ marginTop: "auto" }}
            >
              <ImageOrVideoSet
                fill
                sizes={sizes("75vw", "75vw", "75vw")}
                src={data.largeImage?.image || "/dummy.png"}
                alt={data.largeImage?.imageAlt || getPlainText(data.titleText)}
                imageSet={data.largeImage?.imageSet}
              />
            </div>
          </Block>
        </MobileOnly>
        <TabletOnly>
          <Block
            style={{
              flex: "0 0 336px",
              minWidth: "unset",
              maxHeight: "0 0 336px",
              marginTop: "auto",
            }}
          >
            <div
              className="image"
              data-tina-field={tinaField(data, "largeImage")}
            >
              <ImageOrVideoSet
                fill
                sizes={sizes("75vw", "75vw", "75vw")}
                src={data.largeImage?.image || "/dummy.png"}
                alt={data.largeImage?.imageAlt || getPlainText(data.titleText)}
                imageSet={data.largeImage?.imageSet}
              />
            </div>
          </Block>
        </TabletOnly>
        <Block style={{ flex: "0 0 max(464px, 100%)", minHeight: "0 0 464px" }}>
          <div
            className="image"
            data-tina-field={tinaField(data, "largeImage")}
            style={{ marginTop: "auto" }}
          >
            <ImageOrVideoSet
              fill
              sizes={sizes("75vw", "75vw", "75vw")}
              src={data.largeImage?.image || "/dummy.png"}
              alt={data.largeImage?.imageAlt || getPlainText(data.titleText)}
              imageSet={data.largeImage?.imageSet}
            />
          </div>
        </Block>
      </Container>
    </Wrapper>
  );
}
Gallery5.blockName = "PageBlocksGallery5";
