import { DesktopOnly, MobileOnly, TabletOnly } from "@/lib";
import { PageData } from "@/pages";
import { PageBlocksDivider } from "tina/__generated__/types";
import { Divider } from "../shared/Text";

export default function DividerBlock({
  data,
  global,
}: {
  data: PageBlocksDivider;
  global?: PageData["data"]["global"];
}) {
  return (
    <>
      <DesktopOnly>
        <Divider
          c={data.textColor || (undefined as any)}
          m={
            (global?.verticalGap?.desktop
              ? `calc(-${global?.verticalGap?.desktop} / 2)`
              : "-2rem") +
            " " +
            (global?.sidePadding?.desktop || "calc(100vw / 12)")
          }
          width={`calc(100vw - (${
            global?.sidePadding?.desktop || "calc(100vw / 12)"
          } * 2))`}
        />
      </DesktopOnly>
      <TabletOnly>
        <Divider
          c={data.textColor || (undefined as any)}
          m={
            (global?.verticalGap?.tablet
              ? `calc(-${global?.verticalGap?.tablet} / 2)`
              : "-1rem") +
            " " +
            (global?.sidePadding?.tablet || "2rem")
          }
          width={`calc(100vw - (${global?.sidePadding?.tablet || "2rem"} * 2))`}
        />
      </TabletOnly>
      <MobileOnly>
        <Divider
          c={data.textColor || (undefined as any)}
          m={
            (global?.verticalGap?.mobile
              ? `calc(-${global?.verticalGap?.mobile} / 2)`
              : "-1rem") +
            " " +
            (global?.sidePadding?.mobile || "2rem")
          }
          width={`calc(100vw - (${global?.sidePadding?.mobile || "2rem"} * 2))`}
        />
      </MobileOnly>
    </>
  );
}
DividerBlock.blockName = "PageBlocksDivider";
