import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  TabletDown,
  desktop,
  mobile,
  tablet,
  toString,
} from "@/lib";
import { Fragment, useState } from "react";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksAnimationVennDiagram,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { QuestionBox } from "../../FAQ/FAQ4";
import {
  Block,
  LeftCircle,
  LinkBlock,
  LogoVenn,
  RightCircle,
  TextCircle,
  TopCircle,
  TopWrapper,
  Wrapper,
} from "./styles";

export default function AnimationVennDiagram({
  data,
  global,
}: {
  data: PageBlocksAnimationVennDiagram;
  global?: GlobalPartsFragment;
}) {
  const [index, setIndex] = useState<number | null>(null);

  return (
    <TopWrapper
      positioning={data.positioning}
      id={data.id || undefined}
      data-tina-field={tinaField(data)}
    >
      <TabletDown>
        <Block
          css={`
            ${tablet(
              css`
                padding: 0 ${global?.sidePadding?.tablet};
              `
            )}
            ${mobile(
              css`
                padding: 0 ${global?.sidePadding?.mobile};
              `
            )}
          `}
        >
          <VennDiagram
            top={data.vennElement1 || "Element 1"}
            left={data.vennElement2 || "Element 2"}
            right={data.vennElement3 || "Element 3"}
          />
        </Block>
      </TabletDown>
      <Wrapper global={global}>
        {data.flipSides && (
          <DesktopOnly>
            <ImageBlock {...{ index, data }} />
          </DesktopOnly>
        )}
        <Block>
          <div
            css={`
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              ${desktop(css`
                padding-right: ${!data.flipSides ? "calc(100vw / 12)" : "0"};
                padding-left: ${data.flipSides ? "calc(100vw / 12)" : "0"};
              `)}

              justify-content: ${data.verticalAlign || "start"};
            `}
          >
            <Text
              t={"headline1"}
              td="headline1"
              tt="headline2"
              tm="headline2"
              data-tina-field={tinaField(data, "title")}
              mt={0}
            >
              <Markdown>{data?.title}</Markdown>
            </Text>

            {data.links?.map((v, i) =>
              toString(v?.text?.children)?.length ? (
                <Fragment key={i}>
                  <QuestionBox
                    {...{ i, index, setIndex }}
                    v={{ ...v, __typename: "PageBlocksFaq4Questions" }}
                    key={i}
                  />
                </Fragment>
              ) : (
                <Fragment key={i}>
                  <SafeLink href={v?.link}>
                    <LinkBlock
                      data-tina-field={v && tinaField(v, "link")}
                      key={i}
                    >
                      <Text
                        t="headline3"
                        as="h2"
                        data-tina-field={v && tinaField(v, "title")}
                        m="0"
                      >
                        {v?.title}
                      </Text>
                      <img
                        alt="Follow the link"
                        src="/icons/arrow-right-large.svg"
                        width="13"
                        height="14"
                      />
                    </LinkBlock>
                  </SafeLink>
                  <Divider c="elements/gray-1" mt="0" mb="0" />
                </Fragment>
              )
            )}
          </div>
        </Block>
        {!data.flipSides && (
          <DesktopOnly>
            <ImageBlock {...{ index, data }} />
          </DesktopOnly>
        )}
      </Wrapper>
    </TopWrapper>
  );
}

function ImageBlock({
  data,
  index,
}: {
  data: PageBlocksAnimationVennDiagram;
  index: number | null;
}) {
  return (
    <Block style={{ flex: "1 1 100%" }}>
      <div
        css={`
          position: relative;
          display: block;

          width: 100%;
        `}
      >
        <VennDiagram
          top={data.vennElement1 || "Element 1"}
          left={data.vennElement2 || "Element 2"}
          right={data.vennElement3 || "Element 3"}
        />
      </div>
    </Block>
  );
}
AnimationVennDiagram.blockName = "PageBlocksAnimationVennDiagram";

function VennDiagram({
  top,
  left,
  right,
}: {
  top: string;
  left: string;
  right: string;
}) {
  return (
    <Block style={{ flex: "1 1 100%" }}>
      <div
        css={`
          object-fit: contain;
          width: 100%;
          overflow: hidden;
          :after {
            content: "";
            display: block;
            padding-bottom: 100%; // trick to have height = width.
          }
        `}
      >
        <TopCircle></TopCircle>
        <LeftCircle></LeftCircle>
        <RightCircle></RightCircle>
        <LogoVenn src="/icons/OPT_logo.svg" alt="OPT Logo" />

        <TextCircle
          css={`
            top: 18%;
            width: 100%;
            text-align: center;
          `}
        >
          {top}{" "}
        </TextCircle>
        <TextCircle
          css={`
            top: 62%;
            left: 5%;
            width: 35%;
            text-align: center;
          `}
        >
          {left}{" "}
        </TextCircle>
        <TextCircle
          css={`
            top: 62%;
            right: 5%;
            width: 35%;
            text-align: center;
          `}
        >
          {right}{" "}
        </TextCircle>
      </div>
    </Block>
  );
}
