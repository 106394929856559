import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { DesktopOnly, TabletDown, desktop, sizes } from "@/lib";
import { getFilters } from "@/lib/filters";
import { css, useTheme } from "styled-components";
import { GlobalPartsFragment, PageBlocksForm1 } from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import { Text } from "../../../shared/Text";
import { ContactForm } from "./contactForm";
import { Column, FormColumn, Row, Wrapper } from "./styles";

export default function Form1({
  data,
  global,
}: {
  data: PageBlocksForm1;
  global?: GlobalPartsFragment;
}) {
  const theme = useTheme();

  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      color={
        data.layout?.backgroundColor
          ? theme.colors[
              data.layout?.backgroundColor as keyof typeof theme.colors
            ]
          : undefined
      }
      global={global}
      positioning={data.layout?.positioning}
      filter={
        data.layout?.filters ? getFilters(data.layout?.filters) : undefined
      }
    >
      {data.layout?.backgroundImage && (
        <ImageOrVideoSet
          fill
          sizes={sizes("100vw", "100vw", "100vw")}
          priority
          alt={
            data.layout?.imageAlt || data.headline || "Title background image"
          }
          src={data.layout?.backgroundImage}
          imageSet={data.layout?.imageSet}
          css={`
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
          `}
        />
      )}
      <TabletDown>
        <Text
          t="display3"
          td="display3"
          tm="headline1"
          tt="headline1"
          mt="0"
          style={{ height: "fit-content" }}
          data-tina-field={tinaField(data, "headline")}
          c={data?.layout?.textColor || (undefined as any)}
        >
          <Markdown>{data.headline}</Markdown>
        </Text>
        {/* <MobileOnly>
          <Divider c="elements/gray-2" mt="1rem" mb="0rem" />
        </MobileOnly> */}
        <Text
          as="div"
          t="body"
          data-tina-field={tinaField(data, "text")}
          c={data?.layout?.textColor || (undefined as any)}
        >
          <Markdown>{data.text}</Markdown>
        </Text>
      </TabletDown>
      <Row
        css={css`
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          ${desktop(
            css`
              gap: 1rem 3rem;
            `
          )}
        `}
      >
        <DesktopOnly>
          <Column
            style={{
              flex: "1 1 100%",
              justifyContent: data.layout?.verticalAlign || "center",
              height: "auto",
            }}
          >
            <div>
              <Text
                t="display3"
                style={{ height: "fit-content" }}
                data-tina-field={tinaField(data, "headline")}
                c={data?.layout?.textColor || (undefined as any)}
              >
                <Markdown>{data.headline}</Markdown>
              </Text>
              <Text
                as="div"
                mt="2rem"
                t="body"
                data-tina-field={tinaField(data, "text")}
                c={data?.layout?.textColor || (undefined as any)}
              >
                <Markdown>{data.text}</Markdown>
              </Text>
            </div>
          </Column>
        </DesktopOnly>
        <FormColumn
          style={{
            backgroundColor: theme.colors["background/bg-1"],
            width: "100%",
            height: "100%",
            flex: "1 1 100%",
          }}
        >
          <ContactForm
            css={desktop(css`
              padding: 2rem;
            `)}
            {...{ data }}
          />
        </FormColumn>
      </Row>
    </Wrapper>
  );
}
Form1.blockName = "PageBlocksForm1";
