import { ImageOrVideo } from "@/components/shared/ImageOrVideo";
import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import { desktop, sizes, tablet, tabletDown } from "@/lib";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksResources2,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import Arrow from "../../../../public/icons/arrow-down.svg";
import { Button } from "../../Hero/styles";
import { Wrapper } from "../styles";
export default function Resources2({
  data,
  global,
}: {
  data: PageBlocksResources2;
  global?: GlobalPartsFragment;
}) {
  return (
    <Wrapper
      data-tina-field={tinaField(data)}
      id={data.id || undefined}
      positioning={data.positioning}
      global={global}
    >
      <div
        css={`
          position: relative;
          display: block;
          width: 100%;
        `}
      >
        <Text
          t="headline1"
          td="headline1"
          tm="headline2"
          tt="headline2"
          c="text/primary"
          mt="0"
          css={`
            position: relative;
            display: flex;
            top: 0;
            left: 0;
            ${tabletDown(
              css`
                position: relative;
              `
            )}
          `}
        >
          {data.title}
        </Text>
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            ${desktop(css`
              grid-template-columns: repeat(4, 1fr);
              margin-top: 4rem;
            `)}
            ${tablet(
              css`
                margin-top: 2rem;
              `
            )}
            flex-wrap: wrap;
            gap: 2rem;
          `}
        >
          {data.features?.map((v, i) => (
            <SafeLink
              key={i}
              href={v?.buttonLink || v?.buttonFile}
              css={`
                position: relative;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                ${tabletDown(
                  css`
                    width: 100%;
                  `
                )}
              `}
            >
              <Text
                t="headline4"
                c="text/primary"
                mt="0"
                css={`
                  font-weight: 400 !important;
                  ${desktop(
                    css`
                      margin: 0.2rem 0 2.2rem 0;
                    `
                  )}
                `}
              >
                {v?.title}
              </Text>

              {v?.preview && (
                <ImageOrVideo
                  fill
                  sizes={sizes("25vw", "25", "25")}
                  data-tina-field={tinaField(v, "preview")}
                  css={`
                    flex: 1 1 auto;
                    position: relative !important;
                    object-fit: cover;
                    height: 200px;
                    object-position: top;
                  `}
                  src={v?.preview}
                  alt={v?.buttonText || ""}
                />
              )}
              {v?.buttonText && (
                <Button
                  css={`
                    margin-top: 0;
                    width: 100%;
                    max-width: 100%;
                    overflow: hidden;
                    position: relative;
                    display: flex;
                    white-space: nowrap;
                  `}
                >
                  {v?.buttonText}
                  <Arrow
                    css={`
                      width: 0.8rem;
                      margin-left: 1rem;
                    `}
                  />
                </Button>
              )}
            </SafeLink>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}
Resources2.blockName = "PageBlocksResources2";
