import { ImageOrVideoSet } from "@/components/shared/ImageOrVideo";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  MobileOnly,
  TabletDown,
  TabletUp,
  desktop,
  sizes,
  tabletDown,
} from "@/lib";
import { useState } from "react";
import { css } from "styled-components";
import {
  GlobalPartsFragment,
  PageBlocksCallToAction1,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import {
  Block,
  BodyText,
  Button,
  Input,
  InputBlock,
  LeftBlock,
  TitleText,
  TopWrapper,
  Wrapper,
} from "./styles";

export default function CallToAction1({
  data,
  global,
}: {
  data: PageBlocksCallToAction1;
  global?: GlobalPartsFragment;
}) {
  const [status, setStatus] = useState<undefined | "success" | "error">();
  return (
    <TopWrapper
      data-tina-field={data}
      id={data.id || undefined}
      positioning={data.positioning}
    >
      <MobileOnly>
        <Block>
          <ImageOrVideoSet
            data-tina-field={tinaField(data, "image")}
            fill
            sizes={sizes("100vw", "100vw", "100vw")}
            src={data.image || "/dummy.png"}
            alt={data.imageAlt || data.title || ""}
            imageSet={data.imageSet}
            css={`
              ${desktop(css`
                margin-left: 1rem;
              `)}
              ${tabletDown(css`
                min-height: 100%;
                margin-right: -2rem;
              `)}
              object-fit: cover;
            `}
          />
        </Block>
      </MobileOnly>
      <Wrapper global={global}>
        <LeftBlock>
          <TitleText
            mt={0}
            t={"headline1"}
            td="headline1"
            tm="headline2"
            tt="headline2"
            mb={"2rem"}
            data-tina-field={tinaField(data, "title")}
          >
            <Markdown>{data?.title}</Markdown>
          </TitleText>
          <BodyText
            t="body"
            c="text/primary"
            mb={"2rem"}
            data-tina-field={tinaField(data, "text")}
          >
            <Markdown>{data?.text}</Markdown>
          </BodyText>
          <form name="CallToAction" action="/connect">
            <>
              {!data.hideInput && (
                <>
                  <TabletDown>
                    <InputBlock>
                      <Input
                        type="email"
                        placeholder={data.inputPlaceholder || "Your email"}
                        data-tina-field={tinaField(data, "inputPlaceholder")}
                      />
                    </InputBlock>
                  </TabletDown>
                </>
              )}
              <InputBlock>
                {!data.hideInput && (
                  <DesktopOnly>
                    <Input
                      type="email"
                      placeholder={data.inputPlaceholder || "Your email"}
                      data-tina-field={tinaField(data, "inputPlaceholder")}
                      // onChange={handleChange}
                    />
                  </DesktopOnly>
                )}
                {data.buttonLink ? (
                  <SafeLink
                    href={data.buttonLink}
                    data-tina-field={tinaField(data, "buttonLink")}
                  >
                    <Button
                      data-tina-field={tinaField(data, "buttonText")}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {data.buttonText}
                    </Button>
                  </SafeLink>
                ) : (
                  <Button
                    data-tina-field={tinaField(data, "buttonText")}
                    type="submit"
                  >
                    {data.buttonText}
                  </Button>
                )}
                {status === "success" && (
                  <Text
                    t="button"
                    as="p"
                    css={`
                      background-color: rgba(255, 255, 255, 0.1);
                      border: 1px solid rgba(255, 255, 255, 0.3);
                      padding: 1rem;
                      color: white;
                      text-align: center;
                      width: 100%;
                    `}
                  >
                    Thanks for your request!
                  </Text>
                )}
                {status === "error" && (
                  <Text
                    t="button"
                    as="p"
                    css={`
                      background-color: rgba(255, 255, 255, 0.1);
                      border: 1px solid rgba(255, 255, 255, 0.3);
                      padding: 1rem;
                      color: white;
                      text-align: center;
                      width: 100%;
                    `}
                  >
                    Sorry, we could not reach our servers. <br /> Please try
                    again later.
                  </Text>
                )}
              </InputBlock>
            </>
          </form>
        </LeftBlock>

        <TabletUp>
          <Block>
            <ImageOrVideoSet
              fill
              sizes={sizes("50vw", "50vw", "50vw")}
              data-tina-field={tinaField(data, "image")}
              src={data.image || "/dummy.png"}
              alt={data.imageAlt || data.title || ""}
              css={`
                position: relative !important;
                margin-left: 0 !important;
              `}
              imageSet={data.imageSet}
            />
          </Block>
        </TabletUp>
      </Wrapper>
    </TopWrapper>
  );
}

CallToAction1.blockName = "PageBlocksCallToAction1";
