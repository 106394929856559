import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import { lighten } from "polished";
import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Row = styled.div<SpaceProps>`
    top: 0;
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    > div {
      flex: 1 1 auto;
      width: 100%;
    }
    ${space}
  `,
  Wrapper = styled.div<{
    filter?: string;
    color?: string;
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      tablet(
        css`
          padding: 80px ${t?.global?.sidePadding?.tablet || "2rem"} 0
            ${t?.global?.sidePadding?.tablet || "2rem"};
          justify-content: flex-end;
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 80px ${t?.global?.sidePadding?.mobile || "2rem"} 0
            ${t?.global?.sidePadding?.mobile || "2rem"};
          justify-content: flex-end;
        `
      )}
    box-sizing: border-box;
    ${(t) => css`
      ::before {
        content: "";
        background-color: ${t.color || "transparent"};
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${t.filter &&
        css`
          filter: ${t.filter};
        `}
      }
    `}
    > *, > * > * {
      z-index: 1;
    }
  `,
  Column = styled.div<SpaceProps>`
    top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex: 1 1 auto;
    ${space}
  `,
  FormColumn = styled(Column)`
    ${desktop(
      css`
        max-width: 560px;
        padding: 3rem;
      `
    )}
    ${tabletDown(
      css`
        padding: 0rem;
        background: none !important;
      `
    )}
  `,
  Button = styled.button`
    ${(t) => t.theme.font.button}
    width: 100%;
    height: 40px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-2"]};
    :hover {
      background-color: ${(t) =>
        lighten(0.02, t.theme.colors["background/bg-2"])};
    }
  `,
  InputCSS = css`
    flex: 1 1 auto;
    width: 100%;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    color: ${(t) => t.theme.colors["text/primary"]};
    ::placeholder {
      color: ${(t) => t.theme.colors["text/primary"]};
    }
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-1"]};
    }
    :active {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    :focus {
      outline: none;
    }
    ${(t) => t.theme.font.button}
  `,
  Input = styled.input`
    height: 40px;
    ${InputCSS}
  `,
  Area = styled.textarea`
    ${InputCSS}
  `;
