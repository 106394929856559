import { desktop, position, PositioningParams } from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
  color?: string;
  fullWidth?: boolean;
  positioning?: PositioningParams | null;
  global?: GlobalPartsFragment;
}>`
  ${(t) => t.positioning && position(t.positioning)}
  ${(t) =>
    !t.fullWidth &&
    desktop(
      css`
        padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
      `
    )}
  width: 100vw;
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  overflow: hidden;
  ${(t) => css`
    background-color: ${t.color
      ? t.theme.colors[t.color as keyof typeof t.theme.colors]
      : t.theme.colors["background/bg-0"]};
  `}
`;
