import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
          > div {
            flex: 1 1 50%;
          }
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"};
          > div,
          > div > div {
            flex: 1 1 50%;
          }
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"};
          > div,
          > div > div {
            flex: 1 1 50%;
          }
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    overflow: hidden;
    grid: 2rem 2rem;
    text-align: start;
    a {
      cursor: pointer;
    }
  `,
  Block = styled.div`
    /* flex: 0 0 50%; */
    min-width: 230px;
    ${desktop(
      css`
        padding-right: 2rem;
        flex: 0 0 50%;
      `
    )}
    ${tabletDown(
      css`
        padding-right: 0;
      `
    )}
  flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;

    img {
      margin-top: auto;
      width: 100%;
    }
  `,
  Numbers = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: 2rem;
    flex-flow: row wrap;
    position: relative;
    justify-content: center;
    gap: 2rem 2rem;
  `,
  NumberBlock = styled.div`
    flex: 0 0 calc(50% - 1rem);
    flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    > div.image {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      img {
        margin-left: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  `,
  TitleText = styled(Text)`
    ${tablet(
      css`
        margin-bottom: 0;
      `
    )}
  `,
  BodyText = styled(Text)`
    ${desktop(
      css`
        margin-bottom: 2rem;
      `
    )}
    ${tabletDown(
      css`
        margin-bottom: 0;
      `
    )}
  `,
  TopWrapper = styled.div<{ positioning?: PositioningParams | null }>`
    ${(t) => t.positioning && position(t.positioning)}
  `;
