import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
  tabletUp,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{
    positioning?: PositioningParams | null;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) => t.positioning && position(t.positioning)}
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    overflow: hidden;
    grid: 2rem 2rem;
    ${desktop(
      css`
        > div {
          flex: 1 1 50%;
        }
      `
    )}
    a {
      cursor: pointer;
    }
  `,
  Block = styled.div`
    /* flex: 0 0 50%; */
    min-width: 230px;
    ${mobile(
      css`
        padding-right: 1rem;
      `
    )}
    ${tabletUp(
      css`
        padding-right: 2rem;
      `
    )}
  flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    > div.image {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      img {
        ${desktop(
          css`
            margin-left: 1rem;
          `
        )}
        ${tabletDown(
          css`
            margin-left: 0rem;
          `
        )}
      position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  `,
  Numbers = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: 2rem;
    flex-flow: row wrap;
    position: relative;
    justify-content: center;
    gap: 2rem 2rem;
  `,
  NumberBlock = styled.div`
    flex: 0 0 calc(50% - 1rem);
    flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    > div.image {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      img {
        margin-left: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  `,
  HeaderBlock = styled(Block)`
    padding-right: 2rem;
    ${tabletDown(
      css`
        flex: 0 0 100%;
      `
    )}
  `,
  Container = styled.div`
    display: flex;
    ${tabletDown(
      css`
        margin-right: calc(100vw / -6);
      `
    )}
    ${mobile(
      css`
        height: 300px;
      `
    )}
  `;
